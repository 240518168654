.main-categories-div {
    .top-heading {
      margin-top: 20px;
      h2 {
        text-align: center;
        font-size: 20px;
      }
    }
    .category-card-section {
      display: flex;
      justify-content: center;
  
      .category-card {
        margin-right: 30px;
        position: relative;
        .col-inner {
          transition: ease-in 0.2s;
          &:hover {
            transform: translateY(-6px);
            box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 10%);
          }
          a {
            .box-image {
              width: 246px;
              height: 296px;
              position: relative;
              object-fit: cover;
              overflow: hidden;
  
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .box-text {
          position: absolute;
          top: 195px;
          left: 0;
          right: 0;
          background-color: white;
          opacity: 0.9;
  
          .box-text-inner {
            h5 {
              margin-bottom: 0;
              font-size: 16px;
              color: #446084;
            }
            p {
              margin-bottom: 0;
              font-size: 16px;
              color: #446084;
            }
          }
        }
      }
    }
  }
  