// Dashboard Notification Popup Css Starts Here
.search-modal {
    
    .modal-heading {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      color: #000000;
      padding: 10px !important;
    }
    .modal-content {
      border-radius: 15px;
      max-width: 487px !important;
      background-color: #ffffff;
    }
    .modal-dialog {
      margin-right: 30px;
      margin-top: 90px;
    }
    .modal-body {
      padding: 25px;
  
      .header {
        h6 {
          font-weight: 500;
          span {
            font-size: 18px;
            font-weight: 500;
          }
        }
        .btns-time {
          cursor: pointer;
          border-radius: 50%;
          width: 25px;
          height: 25px;
        }
      }
      .notification-container {
        height: calc(100vh - 235px);
        overflow-y: scroll;
        &::-webkit-scrollbar {
          // width: 3px;
          display: none;
        }
      }
    }
    .filter-cross {
      background: #ffffff;
      border: 1.29px solid #EAEAEA;
      border-left: none;
      border-radius: 14px;
  
      button {
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        &:focus{
          background-color: transparent;
        }
      }
    }
  
  }
  
  .notif_para {
    /* Lorem ipsum dolor sit amet, consectetur adipiscing elit. */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $black-color;
    width: 300px;
  }
  
  .searchbar {
    background: #ffffff;
    border: 1.29px solid #eaeaea;
    border-radius: 14px;
    border-left: none;
    border-right: none;
    height: 48px;
  
    &:focus {
      box-shadow: none;
      border: 1.29px solid #eaeaea;
      border-left: none;
      border-right: none;
    }
  }
  input{
    border: 1px solid rgb(212, 212, 212);
  }
  .input-group-text {
    background: #ffffff;
    border: 1.29px solid #eaeaea;
    border-right: none;
    border-radius: 14px;
  }
  
  .recent-searches-heading {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    text-align: left;
    color: $black-color;
    margin-top: 25px;
  }
  
  .john-doe {
    /* John doe */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */
    letter-spacing: -0.408px;
    color: $gray-color;
    margin-left: 12px;
  
  }
  
  .search-box-container{
    // max-height: 564px;
    height: calc(100vh - 420px);
    overflow: auto;
    .search-box {
      padding: 10px 0;
      h2 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: $black-color;
        margin-bottom:0;
        padding-left: 12px;
      }
      p {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: $black-color;
        opacity: 0.7;
        margin-bottom:0;
        padding-left: 12px;
    
    
      }
    }
  }
  
  
  
  @media screen and (min-width:0px) and (max-width: 576px){
    .search-box-container{
      // max-height: 325px;;
      // overflow: auto;
      .search-box {
        padding: 10px 0;
        h2 {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          color: $black-color;
          margin-bottom:0;
          padding-left: 12px;
        }
        p {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: $black-color;
          opacity: 0.7;
          margin-bottom:0;
          padding-left: 12px;
      
      
        }
      }
    }
  }