/* Change Password */
$base-color: #ff5a4e;
$secondary-color: #00bcc1;
$primary-fonts: "Anek Malayalam", sans-serif;
$white-color: #ffffff;
$body-color: #f5f5f5;
$white-body-color: #ffffff;
$black-color: #11052c;
$text-color: #817a8e;
$border-color: #f4f4f4;
$error-color: #ff3333;
$input-color: #777186;
$list-color: #2d264b;
$gray-color: #817a8e;
@import url("https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap");

.main-change-password {
  .auth_from {
    background-color: #ffffff;
    border-radius: 15px;
    max-width: 669px;
    height: 689px;
    padding: 54px 128px 0 100px;

    h1 {
      margin-bottom: 0;
      font-family: "Anek Malayalam";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      color: #11052c;
    }
    p {
      font-family: "Anek Malayalam";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #817a8e;
      margin: 0;
    }
    button {
      font-family: "Anek Malayalam";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 60px;
      text-align: center;
      color: #ffffff;
    }
    form {
      margin-top: 40px;
    }
  }
}

/* SIde Menu */
.inputfield {
  margin-bottom: 30px;
}
.noactive {
  .first-div {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #11052c;
    svg {
      margin-right: 18px;
    }
  }
}
.form-switch .form-check-input:checked {
  background-color: #32d74b;
}
.form-switch {
  .form-check-input {
    width: 47px;
    height: 28px;
    &:focus {
      box-shadow: none;
      border: 1px solid #32d74b;
    }
  }
}

.main-sidemenu {
  min-height: 689px;
  background: #ffffff;
  border-radius: 15px;
  padding: 49px 41px 0 43px;
  .main-profile {
    display: flex;
    align-items: center;

    .profile-img {
      border: 1px solid red;
      border-radius: 50%;
      padding: 3px;
      width: 81px;
      height: 81px;
      img {
        border-radius: 50%;
        width: 73px;
        height: 73px;
        object-fit: cover;
      }
    }
    .profile-info {
      padding-left: 15px;

      h2 {
        margin-bottom: 0;

        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        color: $black-color;
      }
      a {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: $secondary-color;
      }
    }
  }

  .navtabs {
    margin-top: 37px;

    a {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #11052c;

      .inputfield {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .first-div {
          svg {
            margin-right: 18px;
          }
        }
      }
      &.active {
        color: #ff5a4e;
        svg {
          path {
            fill: #ff5a4e;
          }
        }
      }
    }
    .modal-style {
      .first-div {
        span {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          color: #11052c;
          margin-left: 18px;
          cursor: pointer;
        }
      }
    }
  }
}

/* Form box */
.iconfeild {
  position: absolute;
  bottom: 12px;
  right: 20px;
  background: white;
  width: 30px;
  text-align: end;
}

.toggle-password {
  cursor: pointer;
}

.right-div {
  overflow-y: scroll;
  height: 100vh;
  padding-bottom: 30px;

  &::-webkit-scrollbar {
    display: none;
  }

  .auth_from {
    background: $white-color;
    border-radius: 14px;
    max-width: 600px;
    margin: auto;
    padding: 33px;
    width: 100%;
    h1 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 20px;
      color: $black-color;
      text-align: center;
      text-transform: capitalize;
      margin-bottom: 10px;
    }

    p {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: $text-color;
      text-align: center;
      margin-bottom: 15px;
      padding-top: 2px;
    }

    .three_partylogin {
      margin-top: 25px;

      h5 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: $black-color;
        margin-bottom: 20px;
        text-align: center;
      }

      ul {
        padding: 0px;
        margin: 0px;
        list-style: none;
        display: flex;
        justify-content: center;
        flex-direction: row;
        gap: 20px;

        li {
          list-style: none;
          width: 50px;
          height: 50px;
          border: 1px solid $border-color;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .already-account-div {
      margin-top: 20px;
      p {
        font-style: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #11052c;
        a {
          font-style: $primary-fonts;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #00bcc1;
        }
      }
    }
  }
}

.main-auth-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
@media screen and (min-width: 1700px) {
  .right-div {
    overflow-y: scroll;
    height: 100vh;
    padding-bottom: 30px;

    &::-webkit-scrollbar {
      display: none;
    }

    .auth_from {
      background: $white-color;
      border-radius: 14px;
      max-width: 600px;
      margin: auto;
      padding: 33px;

      h1 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 46px;
        color: $black-color;
        text-align: center;
        text-transform: capitalize;
        margin-bottom: 10px;
      }

      p {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: $text-color;
        text-align: center;
        margin-bottom: 30px;
        padding-top: 2px;
      }

      .three_partylogin {
        margin-top: 30px;

        h5 {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          color: $black-color;
          margin-bottom: 20px;
          text-align: center;
        }

        ul {
          padding: 0px;
          margin: 0px;
          list-style: none;
          display: flex;
          justify-content: center;
          flex-direction: row;
          gap: 20px;

          li {
            list-style: none;
            width: 50px;
            height: 50px;
            border: 1px solid $border-color;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .already-account-div {
        margin-top: 20px;
        p {
          font-style: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #11052c;
          a {
            font-style: $primary-fonts;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #00bcc1;
          }
        }
      }
    }
  }
}

/* Terms */
.main-terms {
  max-width: 669px;
  height: 689px;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 54px 70px 0 99px;

  h2 {
    margin-bottom: 0;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: #11052c;
  }

  .terms-content {
    padding-top: 18px;

    p {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #817a8e;
    }
  }
}

/* FAQ */
.main-accordion {
  max-width: 669px;
  height: 689px;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 54px 90px 0 100px;

  h2 {
    margin: 0;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: #11052c;
    padding-bottom: 24px;
  }

  .accordion {
    .accordion-item {
      border: none;
      .accordion-header {
        padding-bottom: 0;

        button {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #11052c;
          padding-left: 0;

          &:focus {
            box-shadow: none;
            background-color: transparent;
            color: #11052c;
          }
        }
      }
    }
    .accordion-body {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #817a8e;
      padding-top: 0;
      padding-left: 0;
    }
  }
}

.accordion-button::after {
  background-image: url("images/settings/arrowdown.svg");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("images/settings/arrowdown.svg");
}
.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #11052c;
  box-shadow: none;
}

/* Share App Modal */

.main-share-app-modal {
  .modal-dialog {
    max-width: 598px !important;
    height: 460px;
  }
  .share-app-modal {
    border-radius: 20.5767px;
    background-color: white;

    .close-icon {
      text-align: right;
      padding-top: 31px;
      padding-right: 31px;
      width: 16px;
      height: 16px;
      margin-left: auto;
      svg {
        cursor: pointer;
      }
    }
    .setupprofile-content {
      padding-left: 78px;
      padding-right: 78px;
      h2 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        color: $black-color;
        text-align: center;
        padding-top: 43px;
        margin: 0;
      }
      p {
        font-family: "Anek Malayalam", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        max-width: 333px;
        padding-top: 7px;
        margin: 0 auto;
        color: #817a8e;
        text-align: center;
      }
      input {
        background: #ffffff;
        border: 1.28571px solid #f4f4f4;
        border-radius: 14px;
        height: 50px;
        margin-top: 30px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #777186;
      }
      .button-div {
        padding-bottom: 64px;
        padding-top: 43px;
        a {
          text-decoration: none;
          color: $white-color;
          background-color: $base-color;
          margin: 0 auto;
          border-radius: 16px;
          height: 60px;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 60px;
          color: $white-color;
          width: auto;
          max-width: 441px;
          display: block;
          text-align: center;
          &:hover {
            background: $secondary-color;
            transition: linear 0.3s;
          }
        }
      }
    }
  }
}

/* Exit Delete Modal */
.exitmodalcss {
  .modal-dialog {
    max-width: 598px !important;
    height: 100%;
  }

  .modal-content {
    border-radius: 20.5767px;
  }

  .exit-delete-modal {
    height: 280px;

    background-color: white;
    border-radius: 10px;

    .close-icon {
      text-align: right;
      padding-top: 31px;
      padding-right: 31px;
      width: 16px;
      height: 16px;
      margin-left: auto;
      svg {
        cursor: pointer;
      }
    }
    .exit-delete-content {
      max-width: 441px;
      margin: 0 auto;

      h2 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        color: $black-color;
        text-align: center;
        margin: 0;
        margin-top: 58px;
      }
      p {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        margin: 0;
        margin-top: 8px;
        color: $gray-color;
        padding: 0 46px;
      }

      .join-group-form {
        .form-label {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          color: $black-color;
          margin-top: 30px;
          margin-bottom: 15px;
        }
        input {
          background: #ffffff;
          border: 1.28571px solid #f4f4f4;
          border-radius: 14px;
          height: 50px;
          // Placeholder text inside input field
          /* Enter group code */
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: #777186;
          padding-left: 20px;

          &:focus {
            box-shadow: none;
            outline: none;
            border: 1.28571px solid $base-color;
            transition: 0.4s;
          }
        }
      }

      .exit-btn {
        /* Button */
        width: 164px;
        height: 58px;
        background: #ff5a4e;
        border-radius: 16px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        color: #ffffff;
        border: none;
        border: 1px solid#ff5a4e;

        &:hover,
        :focus {
          border: 1px solid#ff5a4e;
          background: #ffffff;
          color: $base-color;
          transition: 0.3s ease-in;
        }
      }

      .cancel-btn {
        /* Button */
        width: 164px;
        height: 58px;
        border: 1px solid #777186;
        background: transparent;
        border-radius: 16px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
        /* identical to box height */
        text-align: center;
        color: #777186;

        &:hover,
        :focus {
          background: #777186;
          color: white;
          transition: 0.3s ease-in;
        }
      }

      .exit-buttons-position {
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
    }
  }
}

/* Variable scss */

.setting-body {
  background-color: #f5f5f5;
}
.custom_form {
  max-width: 440px;
  margin: auto;

  .group_box {
    position: relative;
    margin-bottom: 15px;

    label {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: $black-color;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .from_box {
      position: relative;

      input {
        max-width: 441px;
        height: 50px;
        background: $white-color;
        border: 1px solid $border-color;
        border-radius: 14px;
        padding: 0px 21px 0px 60px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: $input-color;

        &:focus {
          box-shadow: none;
          outline: none;
          border-color: $secondary-color;
        }
      }

      &.email_icon:before {
        content: "";
        background-image: url("images/auth_image/email.svg");
        height: 20px;
        width: 23px;
        display: block;
        position: absolute;
        opacity: 1;
        z-index: 999;
        top: 15px;
        left: 21px;
      }

      &.fullname_icon:before {
        content: "";
        background-image: url("images/auth_image/user.svg");
        background-repeat: no-repeat;
        height: 24px;
        width: 23px;
        display: block;
        position: absolute;
        opacity: 1;
        z-index: 999;
        top: 15px;
        left: 21px;
      }

      &.referral_icon:before {
        content: "";
        background-image: url("images/auth_image/share.svg");
        background-repeat: no-repeat;
        height: 24px;
        width: 23px;
        display: block;
        position: absolute;
        opacity: 1;
        z-index: 999;
        top: 15px;
        left: 21px;
      }
      &.pass_icon:before {
        content: "";
        background-image: url("images/auth_image/lock.svg");
        background-repeat: no-repeat;
        height: 24px;
        width: 24px;
        display: block;
        position: absolute;
        opacity: 1;
        z-index: 999;
        top: 15px;
        left: 21px;
      }

      .error_from {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $error-color;
        padding: 5px;
        position: absolute;
        left: 0px;
        bottom: -25px;

        &.active {
          display: block;
        }
      }
    }
  }

  .form-check {
    margin-top: 5px;
    label {
      font-style: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #7d716e;
    }
    input {
      width: 18px;
      height: 18px;
      border: 1px solid #817a8e;
      border-radius: 3.75px;
      cursor: pointer;
      &:focus {
        box-shadow: none;
      }
      &:checked {
        background-color: #ff5a4e;
        border: none;
      }
    }
  }
}

.forget_links {
  // margin-bottom: 40px;

  a {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    text-align: right;
    color: $secondary-color;
    display: block;
    text-decoration: none;
    transition: linear 0.3s;

    &:hover {
      color: $base-color;
      transition: linear 0.3s;
    }
  }
}

.btn-commom button {
  background: $base-color;
  border-radius: 14px;
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  color: $white-color;
  height: 60px;
  width: 100%;
  border: 0px;
  padding: 0px;
  transition: linear 0.3s;
  margin: 0px;

  &:hover {
    background: $secondary-color;
    transition: linear 0.3s;
  }
}

.btn-back a {
  background: $white-color;
  border-radius: 14px;
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  color: $secondary-color;
  border: 1px solid $secondary-color !important;
  height: 60px;
  width: 100%;
  border: 0px;
  padding: 0px;
  transition: linear 0.3s;
  margin: 0px;
  display: block;
  &:hover {
    background: transparent;
    border: 1px solid $base-color !important;
    color: $base-color;
    transition: linear 0.3s;
  }
}

.mt-40 {
  margin-top: 40px;
}
.mt-46 {
  margin-top: 46px;
}

.grey-color {
  color: #817a8e;
}
// Get Started Css Starts Here
.get-started {
  margin-top: 30px;
  margin-bottom: 60px;
  .container {
    max-width: 1170px;
  }
  h2 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: #23143e;
    margin-bottom: 0;
  }
  p {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #23143e;
    margin-bottom: 0;
  }
}
.box {
  .get-started-box {
    width: 270px;
    height: 180px;
    border-radius: 25.2px;
    display: flex;
    align-items: end;
    margin-top: 30px;
    position: relative;
    &:after {
      content: "";
      background-image: url("images/home_page/bgafter.png");
      width: 110px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-repeat: no-repeat;
    }
    .box-data {
      margin: 18px;
      .box-info {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: $white-color;
        margin-top: 5px;
        width: 70%;
      }
    }
  }
}
.see-all {
  margin-top: 30px;
  a {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    text-align: right;
    color: #00bcc1;
  }
}
.row-margin {
  margin-top: 39px;
}
.ratios {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: 15.1549px;
  line-height: 22px;
  /* identical to box height */
  /* white */
  color: $white-color;
  position: absolute;
  top: 15px;
  right: 15px;
}
// Conversation Starters Box
.conversation-starter-box {
  border-radius: 15.12px;
  min-height: 270px;
  min-width: 270px;
  margin-top: 30px;
  padding: 22px;
  position: relative;
  &:after {
    content: "";
    background-image: url("images/home_page/bgaftertwo.png");
    width: 90px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-repeat: no-repeat;
  }
  .starter-section {
    background: $white-color;
    border-radius: 27px;
    height: 27px;
    width: 123px;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 22px;
    color: $black-color;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .conversation-section {
    position: absolute;
    bottom: 20px;
    .conversation-title {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 700;
      font-size: 21.6px;
      line-height: 31px;
      color: $white-color;
    }
    .conversation-desc {
      /* Lorem ipsum dolor sit amet, consectetur adipiscing elit. */
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 15.12px;
      line-height: 22px;
      color: $white-color;
    }
  }
}
.video-card {
  background: #ffffff;
  border-radius: 14.8235px;
  max-width: 270px;
  min-height: 291px;
  margin: auto;
  margin-top: 30px;
  padding: 10px;
  .video-title {
    margin-top: 10px;
    /* Communication flow This is not meant to be read Uploaded on: 12/06/2022 */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 160%;
    color: #00bcc1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 220px;
  }
  .video-description {
    font-size: 16px;
    font-family: $primary-fonts;
    color: $black-color;
    font-weight: 500;
  }
  .video-date {
    color: #817a8e;
    font-size: 16px;
    font-weight: 500;
    font-family: $primary-fonts;
  }
  .thumbnail-container {
    position: relative;
  }
  .video-thumbnail {
    background: linear-gradient(transparent, transparent, black);
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 14px;
    opacity: 0.6;
  }
  .runtime {
    /* 04:40 */
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 12.7059px;
    color: #ffffff;
    margin: auto;
    margin-left: 5px;
  }
  .play-video {
    position: absolute;
    bottom: 15px;
    left: 10px;
    cursor: pointer;
  }
}
.cursor-variable {
  cursor: pointer;
}
.links {
  /* commonthread/aiden_m.... */
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #00bcc1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.three-dot-menu {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #777186;
}
.mt-25 {
  margin-top: 25px;
}

.mr-18 {
  margin-right: 18px;
}

.mt_10 {
  margin-top: 10px;
}
.mt_20 {
  margin-top: 20px;
}
.mt_30 {
  margin-top: 30px;
}

.orange-button-modal {
  text-decoration: none;
  color: $white-color;
  background-color: $base-color;
  margin: 0 auto;
  border-radius: 16px;
  height: 60px;
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 60px;
  color: $white-color;
  width: 100%;
  border: none;
  max-width: 441px;
  display: block;
  text-align: center;
  &:hover {
    background: $secondary-color;
    transition: linear 0.3s;
  }
}

.text-red {
  color: red;
}
.ml-auto {
  display: flex;
  justify-content: end;
}

.Toastify__toast {
  width: 408px;
  height: 108px;
  right: 95px;
}

.Toastify__toast-icon {
  width: 52px !important;
  height: 52px !important;
}
.toast-text {
  h2 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #11052c;
  }
  p {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #817a8e;
  }
}

.white-bg {
  background-color: #ffffff;
}

.text-capitalize {
  text-transform: capitalize;
}

.width-fit-content {
  width: fit-content;
}

.hidden {
  display: none;
}

/* Responsive CSS */
// Groups Responsive Css Starts Here

@media (max-width: 768px) {
  .groups {
    position: relative;
    height: calc(100vh - 200px);
    .no-groups {
      width: 90%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
    }
  }
}

// Groups Responsive Css Ends Here

// User Profile Responsiveness Css Starts Here

@media (max-width: 992px) {
  .refer-btn {
    width: 162px;
    height: 50px;
    margin: 10px 0;
    font-size: 16px;
  }

  .user-profile {
    margin-top: 30px;
    .container {
      max-width: 1170px;
    }
    .top-section {
      margin-bottom: 30px;
      h2 {
        /* Aiden Methew */
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 34px;
        color: $black-color;
        margin-bottom: 0;
      }
    }

    .main-section {
      .profile-sidebar {
        height: 100%;
        background: #ffffff;
        border-radius: 15px;
        padding: 30px;
      }

      .profile-right-section {
        height: 100%;
        background: #ffffff;
        border-radius: 15px;
        padding: 30px;

        .nav-tabs {
          border: none;
          width: 343px;
          height: 58px;
          background: #f4f4f4;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;

          button {
            width: 170px;
            height: 50px;
            border-radius: 10px;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: $gray-color;
            border: none;
          }
          .nav-link {
            background: #f4f4f4;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #817a8e;
            padding-bottom: 10px;
            position: relative;

            &.active {
              background: #ff5a4e;
              font-family: $primary-fonts;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 150%;
              text-align: center;
              color: #fbfbfd;
              transition: 0.4s ease-in;

              &::after {
                content: ".";
                position: absolute;
                bottom: 7px;
                width: 4px;
                height: 4px;
                border-radius: 150px;
                left: 49%;
                background-color: white;
              }
            }
          }
        }

        .threads {
          margin-top: 20px;

          h3 {
            height: 18px;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 100%;
            color: $black-color;
            margin-top: 20px;
            margin-bottom: 0;
          }
          p {
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #ff5a4e;
            margin-top: 10px;
          }
          .placeholder-box {
            height: 50px;
            /* white */
            background: #ffffff;
            border: 1px solid #f1f1f1;
            border-radius: 9.24638px;
            padding: 15px 12px;
            display: inline-block;
            margin-left: 8px;
            margin-top: 10px;
          }
          .placeholder-box:first-child {
            margin-left: 0;
          }
          .placeholder-box-text {
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $black-color;
          }
        }
      }

      .img-box {
        width: 81px;
        height: 81px;
        border: 1px solid $base-color;
        border-radius: 50%;
        padding: 3px;
        cursor: pointer;
      }

      .profile-details {
        margin-left: 0;
        margin-top: 16px;
        .profile-title {
          /* Aiden Methew */
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 100%;
          color: $black-color;
        }
        .links {
          /* commonthread/aiden_m.... */
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          color: #00bcc1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      h3 {
        /* Talking Point */
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: $black-color;
        margin-top: 20px;
        margin-bottom: 7px;
      }
      p {
        /* Lately, I've been obsessed with exploring Austin, let me know your recommendations! */
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: $gray-color;
        margin: 0;
      }

      hr {
        width: 100%;
        height: 1px;
        background: #817a8e;
        opacity: 0.2;
        margin: 0;
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 400px) {
  .user-profile {
    margin-top: 30px;
    .container {
      max-width: 1170px;
    }
    .top-section {
      margin-bottom: 30px;
      h2 {
        /* Aiden Methew */
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 34px;
        color: $black-color;
        margin-bottom: 0;
      }
    }

    .main-section {
      .profile-sidebar {
        height: 100%;
        background: #ffffff;
        border-radius: 15px;
        padding: 30px;
      }

      .profile-right-section {
        height: 100%;
        background: #ffffff;
        border-radius: 15px;
        padding: 30px;

        .nav-tabs {
          border: none;
          width: 100%;
          height: 58px;
          background: #f4f4f4;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;

          button {
            width: 100%;
            height: 50px;
            border-radius: 10px;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: $gray-color;
            border: none;
          }
          .nav-link {
            background: #f4f4f4;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #817a8e;
            padding-bottom: 10px;
            position: relative;

            &.active {
              background: #ff5a4e;
              font-family: $primary-fonts;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 150%;
              text-align: center;
              color: #fbfbfd;
              transition: 0.4s ease-in;

              &::after {
                content: ".";
                position: absolute;
                bottom: 7px;
                width: 4px;
                height: 4px;
                border-radius: 150px;
                left: 49%;
                background-color: white;
              }
            }
          }
        }

        .threads {
          margin-top: 20px;

          h3 {
            height: 18px;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 100%;
            color: $black-color;
            margin-top: 20px;
            margin-bottom: 0;
          }
          p {
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #ff5a4e;
            margin-top: 10px;
          }
          .placeholder-box {
            height: 50px;
            /* white */
            background: #ffffff;
            border: 1px solid #f1f1f1;
            border-radius: 9.24638px;
            padding: 15px 12px;
            display: inline-block;
            margin-left: 8px;
            margin-top: 10px;
          }
          .placeholder-box:first-child {
            margin-left: 0;
          }
          .placeholder-box-text {
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $black-color;
          }
        }
      }

      .img-box {
        width: 81px;
        height: 81px;
        border: 1px solid $base-color;
        border-radius: 50%;
        padding: 3px;
        cursor: pointer;
      }

      .profile-details {
        margin-left: 0;
        margin-top: 16px;
        .profile-title {
          /* Aiden Methew */
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 100%;
          color: $black-color;
        }
        .links {
          /* commonthread/aiden_m.... */
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          color: #00bcc1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      h3 {
        /* Talking Point */
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: $black-color;
        margin-top: 20px;
        margin-bottom: 7px;
      }
      p {
        /* Lately, I've been obsessed with exploring Austin, let me know your recommendations! */
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: $gray-color;
        margin: 0;
      }

      hr {
        width: 100%;
        height: 1px;
        background: #817a8e;
        opacity: 0.2;
        margin: 0;
        margin-top: 20px;
      }
    }
  }
}

// User Profile Responsiveness Css Ends Here

// Modals Responsive Css Starts Here

@media (max-width: 500px) {
  .exitmodalcss {
    .modal-dialog {
      max-width: 100% !important;
      height: 100%;
    }

    .exit-delete-modal {
      height: 100%;
      .exit-delete-content {
        max-width: 441px;
        margin: 0 auto;

        .exit-btn {
          /* Button */
          width: 164px;
          height: 58px;
          background: #ff5a4e;
          border-radius: 16px;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 29px;
          text-align: center;
          color: #ffffff;
          border: none;
          border: 1px solid#ff5a4e;

          &:hover,
          :focus {
            border: 1px solid#ff5a4e;
            background: #ffffff;
            color: $base-color;
            transition: 0.3s ease-in;
          }
        }

        .exit-buttons-position {
          display: block;
          text-align: center;
          margin-top: 30px;
        }
      }
    }
  }

  .copy-url-modal {
    .modal-dialog {
      max-width: 408px !important;
      height: 100%;
    }

    .modal-content {
      border-radius: 20.5767px;
    }

    .referral-bonus-modal {
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      .close-icon {
        text-align: right;
        padding-top: 31px;
        padding-right: 31px;
        width: 16px;
        height: 16px;
        margin-left: auto;
        svg {
          cursor: pointer;
        }
      }

      .form-container {
        width: 100%;
        height: 398px;

        .edit-profile-label {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          color: $black-color;
          text-align: left;
          margin-bottom: 15px;
        }

        input {
          /* Text Filed */
          background: #ffffff;
          border: 1.28571px solid #f4f4f4;
          border-radius: 14px;
          border-left: none;
          height: 50px;

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }

        span#basic-addon1 {
          margin-right: -4px;
          background: #ffffff;
          border: 1.28571px solid #f4f4f4;
          border-top-left-radius: 14px;
          border-bottom-left-radius: 14px;
          border-right: none;
        }
      }

      .copy-url-btn {
        /* Button */
        width: 441px;
        height: 60px;
        /* Primary - Purple Variant */
        background: #ff5a4e;
        border-radius: 16px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        color: #ffffff;
        height: 60px;
        margin-top: 34px;
        border: 1px solid $base-color;
      }

      .share-app-input {
        input {
          height: 50px;
          background: #ffffff;
          border: 1.28571px solid #f4f4f4;
          border-radius: 14px;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 100%;
          color: $gray-color;
          padding: 12px 20px;

          &:focus {
            box-shadow: none;
            outline: none;
            border: 1.28571px solid $base-color;
            transition: 0.3s;
          }
        }
      }

      .referral-bonus-content {
        max-width: 100%;
        margin: auto;
        text-align: center;
        padding: 28px;

        h2 {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          text-align: center;
          color: $black-color;
          margin-bottom: 0;
        }
        p {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          text-align: center;
          color: $gray-color;
          margin-top: 0;
        }

        .referral-bonus-form {
          .form-label {
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: $black-color;
            margin-top: 30px;
            margin-bottom: 15px;
          }
          input {
            background: #ffffff;
            border: 1.28571px solid #f4f4f4;
            border-radius: 14px;
            height: 50px;
            // Placeholder text inside input field
            /* Enter group code */
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;
            color: #777186;
            padding-left: 20px;

            &:focus {
              box-shadow: none;
              outline: none;
              border: 1.28571px solid $base-color;
              transition: 0.4s;
            }
          }
        }

        .button-div {
          padding-bottom: 64px;
          padding-top: 43px;
          a {
            text-decoration: none;
            color: $white-color;
            background-color: $base-color;
            margin: 0 auto;
            border-radius: 16px;
            height: 60px;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 60px;
            color: $white-color;
            width: auto;
            max-width: 441px;
            display: block;
            text-align: center;
            &:hover {
              background: $secondary-color;
              transition: linear 0.3s;
            }
          }
        }
      }
    }
  }

  .edit-profile-modal {
    .modal-dialog {
      max-width: 100% !important;
      height: 100%;
    }

    .modal-content {
      border-radius: 20.5767px;
    }

    .form-container {
      max-width: 100%;
      height: 398px;

      .edit-profile-label {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: $black-color;
        text-align: left;
        margin-bottom: 15px;
      }

      input {
        /* Text Filed */
        background: #ffffff;
        border: 1.28571px solid #f4f4f4;
        border-radius: 14px;
        border-left: none;
        height: 50px;

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      span#basic-addon1 {
        margin-right: -4px;
        background: #ffffff;
        border: 1.28571px solid #f4f4f4;
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
        border-right: none;
      }
    }

    .copy-url-btn {
      /* Button */
      max-width: 100%;
      height: 60px;
      /* Primary - Purple Variant */
      background: #ff5a4e;
      border-radius: 16px;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      text-align: center;
      color: #ffffff;
      height: 60px;
      margin-top: 34px;
      border: 1px solid $base-color;
    }
    .referral-bonus-modal {
      .referral-bonus-content {
        margin: 50px auto 65px auto;
      }
    }
  }
}

@media (max-width: 800px) {
  .referral-body {
    width: 100%;

    .referral-container {
      max-width: 90%;
    }
  }

  .group-details-modal-main {
    .modal-dialog {
      max-width: 100% !important;
      // height: 100%;
    }

    .modal-content {
      border-radius: 20.5767px;
      padding: 46px;
      height: 100%;
    }
  }
}

// Modals Responsive Css Ends Here

// Set Up Profile Responsive Css Starts Here
@media (max-width: 500px) {
  // .setup-profile-modal {
  //   border-radius: 20.5767px;
  //   .close-icon {
  //     text-align: right;
  //     padding-top: 31px;
  //     padding-right: 31px;
  //     width: 16px;
  //     height: 16px;
  //     margin-left: auto;
  //     svg {
  //       cursor: pointer;
  //     }
  //   }
  //   .setupprofile-content {
  //     padding-left: 10px;
  //     padding-right: 10px;
  //   }
  // }
}
@media (max-width: 600px) {
  .authbreadcrumbs {
    margin: 30px 0 60px 0;
  }
}
// Set Up Profile Responsive Css Ends Here

// Referral Bonus Page Css Starts Here
@media (max-width: 768px) {
  .referral-body {
    .referral-container {
      .ref-points-box {
        display: block !important;
        height: 100% !important;
        h2 {
          font-size: 14px !important;
        }
      }
    }
  }
}
// Referral Bonus Page Ends Here

// Notification Modal Responsive Css Starts Here
.notification-modal {
  .modal-content {
    max-width: 100%;
  }
}
// Notification Modal Ends Here Ends Here

// Settings Responsive Css Starts Here
@media (max-width: 992px) {
  .auth_from {
    background-color: #ffffff;
    border-radius: 15px;
    max-width: 100%;
    // height: 100%;
    padding: 54px 128px 0 100px;
    margin-top: 30px;
  }
  .main-terms {
    max-width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 54px 70px 0 99px;
    margin-top: 30px;
  }
  .main-accordion {
    max-width: 100%;
    height: 100%;
    margin-top: 30px;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 54px 90px 0 100px;
  }
}
@media (max-width: 600px) {
  .main-sidemenu {
    padding: 49px 20px 0 20px;
  }
  .auth_from {
    padding: 54px 40px 0 40px;
  }
  .main-terms {
    padding: 54px 40px 0 40px;
  }
  .main-accordion {
    padding: 54px 40px 0 40px;
  }
}
@media (max-width: 400px) {
  .main-sidemenu {
    padding: 49px 20px 0 20px;
    .profile-img {
      width: 81px;
      height: 81px;
      margin-bottom: 20px;
    }
    .main-profile {
      display: block;
    }
  }
  .auth_from {
    padding: 54px 40px 0 40px;
  }
  .main-terms {
    padding: 54px 40px 0 40px;
  }
  .main-accordion {
    padding: 54px 40px 0 40px;
  }
}
// Settings Responsive Css Ends Here

// onboarding
@media (max-width: 767px) {
  .onboarding-box {
    background: #fff2e8;
    border-radius: 25px;
    padding: 25px;
    display: block;
    justify-content: space-between;
    margin-bottom: 30px;
    .onboarding-btn {
      width: 232px;
      height: 45px;
      background: #ff5a4e;
      border-radius: 16px;
      font-family: "Anek Malayalam", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      text-align: center;
      color: #ffffff;
      border: none;
      margin-top: 10px;
    }
  }
}

// setup profile, complete profile modal start
@media screen and (min-width: 0) and (max-width: 576px) {
  .main-setup-profile-modal {
    .setup-profile-modal {
      border-radius: 20.5767px;
      .close-icon {
        text-align: right;
        padding-top: 31px;
        padding-right: 31px;
        width: 16px;
        height: 16px;
        margin-left: auto;
        svg {
          cursor: pointer;
        }
      }
      .setupprofile-content {
        padding-left: 50px;
        padding-right: 50px;
        h2 {
          font-size: 24px;
          padding-top: 43px;
          margin: 0;
        }
        p {
          font-size: 14px;
          margin: 0;
          padding-top: 16px;
        }
        .button-div {
          padding-bottom: 64px;
          padding-top: 43px;
          a {
            margin: 0 auto;
            height: 60px;
            font-size: 18px;
            line-height: 60px;
            color: $white-color;
            width: auto;
            max-width: 300px;
            display: block;
            text-align: center;
            &:hover {
              background: $secondary-color;
              transition: linear 0.3s;
            }
          }
        }
      }
    }
  }

  .main-complete-profile-modal {
    .complete-profile-modal {
      border-radius: 20.5767px;
      .close-icon {
        text-align: right;
        padding-top: 31px;
        padding-right: 31px;
        width: 16px;
        height: 16px;
        margin-left: auto;
        svg {
          cursor: pointer;
        }
      }
      .setupprofile-content {
        padding-left: 50px;
        padding-right: 50px;
        h2 {
          font-size: 24px;
          padding-top: 43px;
          margin: 0;
        }
        p {
          font-size: 14px;
          margin: 0;
          padding-top: 16px;
        }
        .button-div {
          padding-bottom: 64px;
          padding-top: 43px;
          a {
            margin: 0 auto;
            height: 60px;
            font-size: 18px;
            line-height: 60px;
            color: $white-color;
            width: auto;
            max-width: 300px;
            display: block;
            text-align: center;
            &:hover {
              background: $secondary-color;
              transition: linear 0.3s;
            }
          }
        }
      }
    }
  }
}

//setup profile, complete profile modal end

/* Daily Diet Start*/
@media screen and (min-width: 577px) and (max-width: 767px) {
  .drink {
    /* Daily Diet This is not meant to be read Uploaded on: 12/06/2022 */
    font-weight: 600;
    font-size: 28px;
    line-height: 170%;
    margin-top: 25px;
  }

  .daily-diet-desc {
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
  }

  .daily-date {
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
  }

  .main-para-section {
    .para-section {
      margin-top: 25px;

      .sub-diet-para {
        p {
          font-size: 18px;
          line-height: 24px;
          max-width: 571px;
          &:nth-child(2) {
            margin-top: 20px;
          }
        }
      }
    }
    .para-img-div {
      text-align: center;
      .para-image {
        border-radius: 25px;
      }
    }
  }
  .sub-para-margin {
    margin: 30px 0;
  }
  .sub-para-mb {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 0) and (max-width: 576px) {
  .drink {
    /* Daily Diet This is not meant to be read Uploaded on: 12/06/2022 */
    font-weight: 600;
    font-size: 28px;
    line-height: 170%;
    margin-top: 25px;
  }

  .daily-diet-desc {
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
  }

  .daily-date {
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
  }

  .main-para-section {
    .para-section {
      margin-top: 25px;

      .sub-diet-para {
        p {
          font-size: 15px;
          line-height: 22px;
          max-width: 571px;
          &:nth-child(2) {
            margin-top: 20px;
          }
        }
      }
    }
    .para-img-div {
      text-align: center;
      .para-image {
        border-radius: 25px;
      }
    }
  }
  .sub-para-margin {
    margin: 30px 0;
  }
  .sub-para-mb {
    margin-bottom: 30px;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
}

/* Daily Diet End */

/* Video Player Responsive start */
@media screen and (min-width: 1700px) {
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .conversation-masterclass {
    .video-player {
      max-width: 1170px;
      width: 100%;
      height: 517px;
      margin-top: 30px;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .conversation-masterclass {
    .video-player {
      max-width: 1170px;
      width: 100%;
      height: 367px;
      margin-top: 30px;
    }
  }
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .conversation-masterclass {
    .video-player {
      max-width: 1170px;
      width: 100%;
      height: 220px;
      margin-top: 30px;
    }
    .video-details {
      .video-name {
        font-size: 25px;
      }
      .desc {
        font-size: 20px;
        line-height: 135%;
      }
      .date {
        font-size: 16px;
      }
    }
  }
}
/* Video Player Responsive end */

// splash screen start
@media screen and (min-width: 0) and (max-width: 576px) {
  .main {
    width: 100vw;
    height: 100vh;
    position: relative;
    background: #ff5a4e;
    display: flex;
    justify-content: center;
    align-items: center;
    .pulse {
      position: absolute;
      height: 40px;
      width: 40px;
      border-radius: 40px;
      background: white;
      outline: solid 2px white;
      opacity: 0.4;
      outline-offset: 0px;
      animation: pulse 2s ease infinite;
    }
    .pulse:nth-child(1) {
      animation-delay: 0s;
    }
    .pulse:nth-child(2) {
      animation-delay: 0.2s;
    }
    .pulse:nth-child(3) {
      animation-delay: 0.4s;
    }
    .pulse:nth-child(4) {
      animation-delay: 0.6s;
    }

    @keyframes pulse {
      0% {
        outline-offset: 0px;
      }
      100% {
        outline-offset: 120px;
        outline: solid 2px white;
        opacity: 0;
      }
    }

    &::after {
      content: "";
      width: 282.35px;
      height: 191.6px;
      background-image: url("images/splashscreeen/splash.svg");
      background-position: center;
      background-repeat: no-repeat;
      z-index: 9;
      background-size: 200px;
    }
  }
}

//splash screen end

/* Toastify Css Start */
@media screen and (min-width: 0) and (max-width: 576px) {
  /* Toastify css start */
  .Toastify__toast {
    width: 300px;
    height: 95px;
    left: 0;
    margin: 0 auto;
    bottom: 32px;
  }
  .Toastify__toast-icon {
    width: 52px !important;
    height: 52px !important;
  }
  .toast-text {
    h2 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      color: #11052c;
    }
    p {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #817a8e;
    }
  }
  /* Toastify Css end */
}

/* Toastify css end */

/* Left auth box responsive  */
@media screen and (max-width: 1400px) and (max-height: 768px) {
  .auth_section {
    .side_content {
      background: #ff5a4e;
      height: 100vh;
      padding-top: 30px;
      .logo_main {
        margin-bottom: 25px;
      }
      .steps_div {
        margin-top: 10px;
      }
    }
  }

  .steps_div {
    .carousel-inner {
      .carousel-item {
        h4 {
          color: #fff;
          font-family: Anek Malayalam, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          margin-bottom: 5px;
          text-align: center;
        }
        .title_setps {
          color: #fff;
          font-family: Anek Malayalam, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 29px;
          margin-bottom: 0;
          text-align: center;
        }
        .content_setps {
          color: #fbfbfd;
          font-family: Anek Malayalam, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          text-align: center;
        }
      }
    }
  }
}

/* Edit  */

// @media screen and (min-width: 501px) and (max-width: 1560px) {
//   .edit-profile-modal .referral-bonus-modal .referral-bonus-content {
//     max-width: 441px;
//     margin: 50px auto 65px auto;
//     text-align: center;
//   }
// }

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .edit-profile-modal .referral-bonus-modal {
    height: 710px;
    background: #ffffff;
    box-shadow: 0px 4px 25px rgb(0 0 0 / 15%);
    border-radius: 20px;
  }

  .edit-profile-modal .referral-bonus-modal .referral-bonus-content {
    max-width: 441px;
    margin: 32px auto;
    text-align: center;
  }

  .edit-profile-modal .referral-bonus-modal .referral-bonus-content p {
    font-family: "Anek Malayalam", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #817a8e;
    margin-top: 0;
    margin: 8px 70px 0px 70px;
  }

  .edit-profile-modal .referral-bonus-modal .copy-url-btn {
    width: 441px;
    height: 60px;
    background: #ff5a4e;
    border-radius: 16px;
    font-family: "Anek Malayalam", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #ffffff;
    height: 60px;
    margin-top: 20px;
    border: 1px solid #ff5a4e;
  }

  .edit-profile-modal
    .referral-bonus-modal
    .form-container
    .edit-profile-label {
    font-family: "Anek Malayalam", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #11052c;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 25px;
  }
}

// auth form responsive start
@media screen and (min-width: 0px) and (max-width: 576px) {
  .right-div {
    .auth_from {
      h1 {
        font-weight: 600;
        font-size: 28px;
        line-height: 46px;
        margin-bottom: 10px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        margin-bottom: 18px;
        padding-top: 2px;
      }

      .custom_form {
        .group_box {
          label {
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
            margin-bottom: 10px;
            margin-top: 10px;
          }
          .from_box {
            input {
              max-width: 441px;
              height: 50px;
              padding: 0px 21px 0px 60px;
              font-weight: 400;
              font-size: 12px;
              line-height: 23px;
            }
          }
        }
      }
      .three_partylogin {
        h5 {
          font-family: "Anek Malayalam", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 15px;
          color: #11052c;
          margin-bottom: 20px;
          text-align: center;
        }
      }
    }
  }
  .otp-div {
    .otpinputfeild {
      input {
        border-radius: 10.3061px;
        height: 40px !important;
        width: 40px !important;
        margin: 0 5px !important;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}

// share app modal
@media screen and (min-width: 0) and (max-width: 576px) {
  .main-share-app-modal {
    .share-app-modal {
      .setupprofile-content {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .members-card {
    width: 100%;
  }
  .box {
    .get-started-box {
      width: 100%;
    }
  }

  .video-card {
    width: 100%;
    max-width: 100%;
    img {
      width: 100%;
    }
  }
  .group-cards {
    width: 100%;
  }
}

/* Settings  */
@media screen and (min-width: 0) and (max-width: 576px) {
  .main-change-password {
    .auth_from {
      padding: 50px 30px 0 30px;
    }
    .custom_form {
      .group_box {
        input {
          font-size: 10px;
        }
      }
    }
  }
}

/*Notification Modal Search Modal Header Responsive */
@media screen and (min-width: 576px) and (max-width: 768px) {
  .notification-modal {
    .modal-content {
      border-radius: 15px;
      max-width: 100%;
      margin: 0 auto;
    }
    .modal-dialog {
      margin: 40px 30px;
    }
  }
  .search-modal {
    .modal-content {
      border-radius: 15px;
      max-width: 100%;
      margin: 0 auto;
    }
    .modal-dialog {
      margin: 40px 30px;
    }
  }
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .navbar {
    .navbar-brand {
      .logo {
        img {
          width: 70%;
        }
      }
    }
  }

  /*Notification Modal Search Modal */
  .notification-modal {
    .modal-content {
      border-radius: 15px;
      max-width: 100%;
      margin: 0 auto;
    }
    .modal-dialog {
      margin: 40px 30px;
    }

    .noti-content-time-div {
      display: block !important;

      .time-small {
        font-size: 10px;
      }
      .notif_para {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        width: 100%;
      }
    }
  }
  .search-modal {
    .modal-content {
      border-radius: 15px;
      max-width: 100%;
    }
    .modal-dialog {
      margin: 40px 30px;
    }
  }

  /* Reaction  */
  .main-reactions {
    background-color: #d9cdef;
    border-radius: 47.25px;
    height: 60px;
    /* max-width: 371px; */
    width: 220px;

    position: absolute;
    left: -78px;

    bottom: -61px;
    z-index: 9;
  }

  .main-reactions div.emoji-main .emoji-li .emoji-div {
    width: 20px;
    height: 20px;
    margin: 0 auto;
  }

  .main-reactions div.emoji-main .emoji-li span {
    padding-top: 3px;
    display: block;
    line-height: 45px;
    font-size: 10px;
  }

  .main-reactions div.emoji-main {
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
  }
  .main-reactions div.emoji-main .emoji-li {
    padding: 0;
  }

  .referral-body {
    height: 100%;
  }
}

// Settings Responsive Css Starts Here
@media (max-width: 992px) {
  .auth_from {
    background-color: #ffffff;
    border-radius: 15px;
    max-width: 100%;
    // height: 100%;
    padding: 54px 128px 0 100px;
    margin-top: 30px;
  }
  .main-terms {
    max-width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 54px 70px 0 99px;
    margin-top: 30px;
  }
  .main-accordion {
    max-width: 100%;
    height: 100%;
    margin-top: 30px;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 54px 90px 0 100px;
  }
}
@media (max-width: 600px) {
  .main-sidemenu {
    padding: 49px 20px 0 20px;
  }
  .auth_from {
    padding: 54px 40px 0 40px;
  }
  .main-terms {
    padding: 54px 40px 0 40px;
  }
  .main-accordion {
    padding: 54px 40px 0 40px;
  }
}
@media (max-width: 400px) {
  .main-sidemenu {
    padding: 49px 20px 0 20px;
    .profile-img {
      width: 81px;
      height: 81px;
      margin-bottom: 20px;
    }
    .main-profile {
      display: block;
    }
  }
  .auth_from {
    padding: 54px 40px 0 40px;
  }
  .main-terms {
    padding: 54px 40px 0 40px;
  }
  .main-accordion {
    padding: 54px 40px 0 40px;
  }
}
// Settings Responsive Css Ends Here
