.first-footer-css{
    $bgColor: #151414;
$facebook-bg: #3B5998;
$tweeter-bg: #55ACEE;
$whteColor: #ffff;
$greyCol: #757575;
$primaryColor: #ff5e14;

ul {
    margin: 0px !important;
    padding: 0px !important;
}

svg {
    height: 100%;
    width: 100%;
}

.footer-section {
    background: $bgColor;
    position: relative;
}

a {
    transition: 0.5s ease;

    &:hover {
        color: $primaryColor ;
    }
}

.footer-cta {
    border-bottom: 1px solid #373636;
}

.single-cta {
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
        margin-bottom: 1em;
    }

    .icon-img {
        width: 36px;

        svg {
            path {
                fill: $primaryColor
            }
        }
    }
}

.cta-text {
    padding-left: 15px;
    display: inline-block;

    h4 {
        color: $whteColor;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 2px;
    }

    span {
        color: $greyCol;
        font-size: 15px;
    }

    a {
        color: $greyCol
    }
}

.footer-content {
    position: relative;
    z-index: 2;
}

.footer-pattern {
    img {
        position: absolute;
        top: 0;
        left: 0;
        height: 330px;
        background-size: cover;
        background-position: 100% 100%;
    }
}

.footer-logo {
    margin-bottom: 30px;

    img {
        max-width: 200px;
    }
}

.footer-text {
    p {
        margin-bottom: 14px;
        font-size: 14px;
        color: #7e7e7e;
        line-height: 28px;
    }
}

.footer-social-icon {
    span {
        color: $whteColor;
        display: block;
        font-size: 20px;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 20px;
    }

    .social-list {
        display: flex;
        align-items: center;

        .social-item {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.facebook-bg {
    background: $facebook-bg;
}

.twitter-bg {
    background: $tweeter-bg;
}

.google-bg {
    background: #DD4B39;
}

.footer-widget-heading {
    h3 {
        color: $whteColor;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 40px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -15px;
            height: 2px;
            width: 50px;
            background: $primaryColor;
        }
    }
}

.footer-widget {
    ul {
        li {
            display: inline-block;
            float: left;
            width: 50%;
            margin-bottom: 12px;

            a {
                &:hover {
                    color: $primaryColor;
                }

                color: #878787;
                text-transform: capitalize;
            }
        }
    }
}

.subscribe-form {
    position: relative;
    overflow: hidden;

    input {
        width: 100%;
        padding: 14px 28px;
        background: #2E2E2E;
        border: 1px solid #2E2E2E;
        color: $whteColor;
    }

    button {
        position: absolute;
        right: 0;
        background: $primaryColor;
        border: 1px solid $primaryColor;
        top: 0;
        width: 13%;
        height: 100%;
        padding: 0.8em;

        svg {
            path {
                fill: white;
            }
        }
    }
}

.copyright-area {
    background: #202020;
    padding: 25px 0;
}

.copyright-text {
    p {
        margin: 0;
        font-size: 14px;
        color: #878787;

        a {
            color: $primaryColor;
        }
    }
}

.footer-menu {
    li {
        display: inline-block;
        margin-left: 20px;

        &:hover {
            a {
                color: $primaryColor;
            }
        }

        a {
            font-size: 14px;
            color: #878787;
        }
    }
}
}