.select-role-form {
    h3 {
    }
  
    .select-role{
      display: flex;
      align-items: center;
    }
    
    .wrapper {
      display: flex;
      align-items: center;
      flex-flow: row-reverse;
      justify-content: space-between;
      position: relative;
  
      &:has(input:checked) .icon-container{
        background: #00BD70!important;
        path{
            fill: white;
        }
      }

      &:has(input:checked) .registered-as{
        color: #00BD70!important;
      }
  
      &:has(input:checked) label{
        border: 1px solid #00BD70;
      }
  
      label {
        cursor: pointer;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        border-radius: 10px;
        padding: 2rem 1.5rem;
        border: 1px solid #ceced4;
        text-align: left;
        height: 110px;
        display: flex;
        align-items: center;
        background: #f6f6fa;
        border-radius: 20px;
        height: 90px;
        span{
          font-weight: 700;
        }
        .icon-container {
          background: white;
          height: 60px;
          width: 60px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
        }
        &:hover{
          border: 1px solid #00BD70 ;
        }
      }

      input[type=radio]:checked {
        background-color: #00BD70 !important;

    }
  
      input[type="radio"]:checked + label {
          background-color: black!important;
        }
  
      .form-check-input {
        position: absolute;
        top: 50%;
        right: 1.5rem;
        width: 25px;
        height: 34px;
        transform: translateY(-50%);
        background: gray;
  
        
  
  
        &:checked {
          background-image: url("./tickcircle.png");
          background-repeat: no-repeat;
          border: none;
          background-position: center;
  
          ~ label {
            background-color: #00BD70;
            border: 5px solid #00BD70
            ;
          }
        }
      }
    }
  }