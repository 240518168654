

$color_1: #fff;
$color_2: #FFD200;
$color_3: rgba(0,0,0,.2);
$color_4: #828282;
$color_5: #ef5777;
$color_6: #333;
$color_7: #999;
$color_8: #000;
$font-family_1: Raleway,sans-serif;
$font-family_2: Montserrat,sans-serif;
$background-color_1: #333;
$background-color_2: #ef5777;
$background-color_3: #000;
$background-color_4: #fff;

/********************* shopping Demo-1 **********************/
.main-product-card{
    h3.h3{text-align:center;margin:1em;text-transform:capitalize;font-size:1.7em;}
    .product-grid {
        font-family: $font-family_1;
        text-align: center;
        padding: 0 0 72px;
        border: 1px solid rgba(0,0,0,.1);
        overflow: hidden;
        position: relative;
        z-index: 1;
        .product-image {
            position: relative;
            transition: all .3s ease 0s;
            a {
                display: block;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
        .pic-1 {
            opacity: 1;
            transition: all .3s ease-out 0s;
        }
        &:hover {
            .pic-1 {
                opacity: 1;
            }
            .pic-2 {
                opacity: 1;
            }
            .social {
                opacity: 1;
                top: 50%;
            }
            .product-content {
                bottom: 0;
            }
            .title {
                a {
                    color: $color_5;
                }
            }
        }
        .pic-2 {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            transition: all .3s ease-out 0s;
        }
        .social {
            width: 150px;
            padding: 0;
            margin: 0;
            list-style: none;
            opacity: 0;
            transform: translateY(-50%) translateX(-50%);
            position: absolute;
            top: 60%;
            left: 50%;
            z-index: 1;
            transition: all .3s ease 0s;
            li {
                display: inline-block;
                a {
                    color: $color_1;
                    background-color: $background-color_1;
                    font-size: 16px;
                    line-height: 40px;
                    text-align: center;
                    height: 40px;
                    width: 40px;
                    margin: 0 2px;
                    display: block;
                    position: relative;
                    transition: all .3s ease-in-out;
                    &:hover {
                        color: $color_1;
                        background-color: $background-color_2;
                        &:after {
                            opacity: 1;
                        }
                        &:before {
                            opacity: 1;
                        }
                    }
                    &:after {
                        content: attr(data-tip);
                        color: $color_1;
                        background-color: $background-color_3;
                        font-size: 12px;
                        letter-spacing: 1px;
                        line-height: 20px;
                        padding: 1px 5px;
                        white-space: nowrap;
                        opacity: 0;
                        transform: translateX(-50%);
                        position: absolute;
                        left: 50%;
                        top: -30px;
                        content: '';
                        height: 15px;
                        width: 15px;
                        border-radius: 0;
                        transform: translateX(-50%) rotate(45deg);
                        top: -20px;
                        z-index: -1;
                    }
                    &:before {
                        content: attr(data-tip);
                        color: $color_1;
                        background-color: $background-color_3;
                        font-size: 12px;
                        letter-spacing: 1px;
                        line-height: 20px;
                        padding: 1px 5px;
                        white-space: nowrap;
                        opacity: 0;
                        transform: translateX(-50%);
                        position: absolute;
                        left: 50%;
                        top: -30px;
                    }
                }
            }
        }
        .product-discount-label {
            color: $color_1;
            background-color: $background-color_2;
            font-size: 12px;
            text-transform: uppercase;
            padding: 2px 7px;
            display: block;
            position: absolute;
            top: 10px;
            left: 0;
            background-color: $background-color_1;
            left: auto;
            right: 0;
        }
        .product-new-label {
            color: $color_1;
            background-color: $background-color_2;
            font-size: 12px;
            text-transform: uppercase;
            padding: 2px 7px;
            display: block;
            position: absolute;
            top: 10px;
            left: 0;
        }
        .rating {
            color: $color_2;
            font-size: 12px;
            padding: 12px 0 0;
            margin: 0;
            list-style: none;
            position: relative;
            z-index: -1;
            li.disable {
                color: $color_3;
            }
        }
        .product-content {
            background-color: $background-color_4;
            text-align: center;
            padding: 12px 0;
            margin: 0 auto;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -110px;
            z-index: 1;
            transition: all .3s;
        }
        .title {
            font-size: 13px;
            font-weight: 400;
            letter-spacing: .5px;
            text-transform: capitalize;
            margin: 0 0 10px;
            transition: all .3s ease 0s;
            a {
                color: $color_4;
                &:hover {
                    color: $color_5;
                }
            }
        }
        .price {
            color: $color_6;
            font-size: 17px;
            font-family: $font-family_2;
            font-weight: 700;
            letter-spacing: .6px;
            margin-bottom: 8px;
            text-align: center;
            transition: all .3s;
            span {
                color: $color_7;
                font-size: 13px;
                font-weight: 400;
                text-decoration: line-through;
                margin-left: 3px;
                display: inline-block;
            }
        }
        .add-to-cart {
            color: $color_8;
            font-size: 13px;
            font-weight: 600;
        }
    }
}

@media only screen and (max-width:990px) {
	.product-grid {
		margin-bottom: 30px;
	}
}


/* Product Card Style Two */
$color_1: #fff;
$color_2: #212121;
$color_3: #F44336;
$border-color_1: #ddd;
$border-color_2: #FF9800;

/* Truncate */
.shell {
	padding: 80px 0;
}
.wsk-cp-product {
	background: #fff;
	padding: 15px;
	border-radius: 6px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	position: relative;
	margin: 20px auto;
	&:hover {
		.wsk-cp-img {
			top: -40px;
			img {
				box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
			}
		}
		.wsk-cp-text {
			.category {
				>span {
					border-color: $border-color_1;
					box-shadow: none;
					padding: 11px 28px;
				}
				margin-top: 0px;
			}
		}
	}
}
.wsk-cp-img {
	position: absolute;
	top: 5px;
	left: 50%;
	transform: translate(-50%);
	-webkit-transform: translate(-50%);
	-ms-transform: translate(-50%);
	-moz-transform: translate(-50%);
	-o-transform: translate(-50%);
	-khtml-transform: translate(-50%);
	width: 100%;
	padding: 15px;
	transition: all 0.2s ease-in-out;
	img {
		width: 100%;
		transition: all 0.2s ease-in-out;
		border-radius: 6px;
	}
}
.wsk-cp-text {
	padding-top: 150%;
	.category {
		text-align: center;
		font-size: 12px;
		font-weight: bold;
		padding: 5px;
		margin-bottom: 45px;
		position: relative;
		transition: all 0.2s ease-in-out;
		>* {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			-webkit-transform: translate(-50%,-50%);
			-moz-transform: translate(-50%,-50%);
			-ms-transform: translate(-50%,-50%);
			-o-transform: translate(-50%,-50%);
			-khtml-transform: translate(-50%,-50%);
		}
		>span {
			padding: 12px 30px;
			border: 1px solid #313131;
			background: #212121;
			color: $color_1;
			box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
			border-radius: 27px;
			transition: all 0.05s ease-in-out;
		}
	}
	.title-product {
		text-align: center;
		h3 {
			font-size: 20px;
			font-weight: bold;
			margin: 15px auto;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			width: 100%;
		}
	}
	.description-prod {
		p {
			margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
		}
		text-align: center;
		width: 100%;
		height: 62px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		margin-bottom: 15px;
	}
}
.card-footer {
	padding: 25px 0 5px;
	border-top: 1px solid #ddd;
	&:after {
		content: '';
		display: table;
		clear: both;
	}
	&:before {
		content: '';
		display: table;
	}
	.wcf-left {
		float: left;
	}
	.wcf-right {
		float: right;
	}
}
.price {
	font-size: 18px;
	font-weight: bold;
}
a.buy-btn {
	display: block;
	color: $color_2;
	text-align: center;
	font-size: 18px;
	width: 35px;
	height: 35px;
	line-height: 35px;
	border-radius: 50%;
	border: 1px solid #212121;
	transition: all 0.2s ease-in-out;
	&:hover {
		border-color: $border-color_2;
		background: #FF9800;
		color: $color_1;
		text-decoration: none;
	}
	&:active {
		border-color: $border-color_2;
		background: #FF9800;
		color: $color_1;
		text-decoration: none;
	}
	&:focus {
		border-color: $border-color_2;
		background: #FF9800;
		color: $color_1;
		text-decoration: none;
	}
}
.wsk-btn {
	display: inline-block;
	color: $color_2;
	text-align: center;
	font-size: 18px;
	transition: all 0.2s ease-in-out;
	border-color: $border-color_2;
	background: #FF9800;
	padding: 12px 30px;
	border-radius: 27px;
	margin: 0 5px;
	&:hover {
		text-decoration: none;
		color: $color_1;
	}
	&:focus {
		text-decoration: none;
		color: $color_1;
	}
	&:active {
		text-decoration: none;
		color: $color_1;
	}
}
.red {
	color: $color_3;
	font-size: 22px;
	display: inline-block;
	margin: 0 5px;
}
@media screen and (max-width: 991px) {
	.wsk-cp-product {
		margin: 40px auto;
		.wsk-cp-img {
			top: -40px;
			img {
				box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
			}
		}
		.wsk-cp-text {
			.category {
				>span {
					border-color: $border-color_1;
					box-shadow: none;
					padding: 11px 28px;
				}
				margin-top: 0px;
			}
		}
	}
	a.buy-btn {
		border-color: $border-color_2;
		background: #FF9800;
		color: $color_1;
	}
}
