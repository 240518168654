.banner-section{
    background-image: url("/components/BaileyComponents/bg-breadcrumb.jpg");
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ul{
        list-style: none;
        text-decoration: none;
        display: flex;

        li{
            color: white;
            font-size: 16px;
            margin-left: 20px;
            font-weight: 500;
            &:hover{
                color: rgb(255, 166, 0);
                cursor: pointer;
            }
        }
    }

    .banner-title{
        font-size: 38px;
        color: white;
        font-weight: 600;
        margin-bottom: 20px;
    }

    
}


.category-box{
    background: white;
    padding: 20px;
    border: 1px solid rgba(119, 136, 153, 0.103);

    .category-heading{
        font-size: 20px;
        color: black;
        font-weight: 400;
        border-bottom: 1px solid rgb(255, 166, 0);
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .category-types{
        font-size: 14px;
        color: gray;
        border-bottom: 1px solid rgba(128, 128, 128, 0.151);
        padding-bottom: 10px;
        margin-bottom: 15px;
    }
    :last-child{
        border: none!important;
    }

}

.filter-box{
    background: white;
    padding: 20px;
    border: 1px solid rgba(119, 136, 153, 0.103);
    margin-top: 20px;
    margin-bottom: 20px;

    .filter-heading{
        font-size: 20px;
        color: black;
        font-weight: 400;
        border-bottom: 1px solid rgb(255, 166, 0);
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    .filter-types{
        font-size: 14px;
        color: gray;
        border-bottom: 1px solid rgba(128, 128, 128, 0.151);
        padding-bottom: 10px;
        margin-bottom: 15px;
    }
}

.color-box{
    background: white;
    padding: 20px;
    border: 1px solid rgba(119, 136, 153, 0.103);
    margin-top: 20px;
    margin-bottom: 20px;

    .color-heading{
        font-size: 20px;
        color: black;
        font-weight: 400;
        border-bottom: 1px solid rgb(255, 166, 0);
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .color-circle{
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
    
    .color-name{
        font-size: 14px;
        color: gray;
        margin-left: 10px;
        cursor: pointer;
    }
}

.tags-box{
    background: white;
    padding: 20px;
    border: 1px solid rgba(119, 136, 153, 0.103);
    margin-top: 20px;
    margin-bottom: 20px;

    .tags-heading{
        font-size: 20px;
        color: black;
        font-weight: 400;
        border-bottom: 1px solid rgb(255, 166, 0);
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    ul{
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;
        li{
            display: inline-flex;
            padding: 10px 20px;
            background: rgb(228, 228, 228);
            border-radius: 5px;
            font-size: 14px;
            &:hover{
                background: rgb(255, 182, 47);
                cursor: pointer;    
                transition: 0.3s ease-in;
                color: white;            
            }
        }
    }
    

}


.products-box{
    background: white;
    padding: 20px;
    border: 1px solid rgba(119, 136, 153, 0.103);
    margin-top: 20px;
    margin-bottom: 20px;

    .products-heading{
        font-size: 20px;
        color: black;
        font-weight: 400;
        border-bottom: 1px solid rgb(255, 166, 0);
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .img-box{
        width: 70px;
        height: 70px;
        border: 1px solid gray;
        margin-right: 30px;
        cursor: pointer;
    }

    .product-title{
        font-weight: 600;
        font-size: 14px;
        font-family: sans-serif;
        color: black;
        cursor: pointer;
    }

    .product-price{
        color: rgb(255, 166, 0);
        font-weight: 600;
        font-size: 14px;
        font-family: sans-serif;
        cursor: pointer;
    }
    

}

.breadcrumb-box{
    background: white;
    padding: 15px;
    border: 1px solid rgba(119, 136, 153, 0.103);
    min-height: 30px;
    padding-bottom: 10px;
    a{
        text-decoration: none;
        font-style: none;
        color: gray;
        &.active{
            text-decoration: none;
            font-style: none;
            color: orange;
        }
    }

    .breadcrumb-item.active {
        color: rgb(224, 146, 0);
    }
}


@media (max-width: 768px) {
    .banner-section{
      height: auto;
    }
    .bailey-tabs{
      ul{
        display: block;
        text-align: center;
        padding-left: 0;
        li{
          margin: 0;
          margin-bottom: 8px;
        }
      }
    }
  }