.signin-syntax {
  .container {
    max-width: 1520px;
    // border: 2px solid black;
    .signin-container {
      background: white;
      height: 860px;
      margin-top: 50px;
      background-image: url("../../components/SigninPageSyntax/images/signinpage.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
    }
    .signin-data {
      .already-account {
        /* Already have an account? */
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #5c5c5c;
        span {
          color: #00bd70;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          text-transform: uppercase;
        }
      }

      hr {
        opacity: 0.1;
        border: 1px solid #00bd70;
      }

      .main-heading {
        width: 339px;
        height: 44px;
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        color: #00bd70;
        margin-top: 50px;
      }

      .sub-heading {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
        margin-top: 10px;
      }

      .description {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #5c5c5c;
        margin-top: 10px;
      }

      .short-hr{
        hr{
            opacity: 0.1;
            border: 1px solid #00bd70;
            width: 120px;
            margin-top: 50px;
        }
      }
    }
  }
}
