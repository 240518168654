.our-team-section{
    $color_1: #fff;
$color_2: #007b5e;
$background-color_1: #108d6f;
$background-color_2: #007b5e;
$border-color_1: #108d6f;
$border-color_2: #007b5e;

#team {
	background: #eee !important;
	height: 100vh;

	.card {
		border: none;
		background: #ffffff;
	}
}
.btn-primary {
	&:hover {
		background-color: $background-color_1;
		border-color: $border-color_1;
		box-shadow: none;
		outline: none;
	}
	&:focus {
		background-color: $background-color_1;
		border-color: $border-color_1;
		box-shadow: none;
		outline: none;
	}
	color: $color_1;
	background-color: $background-color_2;
	border-color: $border-color_2;
}
section {
	padding: 60px 0;
	.section-title {
		text-align: center;
		color: $color_2;
		margin-bottom: 50px;
		text-transform: uppercase;
	}
}
.image-flip {
	&:hover {
		.backside {
			-webkit-transform: rotateY(0deg);
			-moz-transform: rotateY(0deg);
			-o-transform: rotateY(0deg);
			-ms-transform: rotateY(0deg);
			transform: rotateY(0deg);
			border-radius: .25rem;
		}
		.frontside {
			-webkit-transform: rotateY(180deg);
			-moz-transform: rotateY(180deg);
			-o-transform: rotateY(180deg);
			transform: rotateY(180deg);
		}
	}
}
.image-flip.hover {
	.backside {
		-webkit-transform: rotateY(0deg);
		-moz-transform: rotateY(0deg);
		-o-transform: rotateY(0deg);
		-ms-transform: rotateY(0deg);
		transform: rotateY(0deg);
		border-radius: .25rem;
	}
	.frontside {
		-webkit-transform: rotateY(180deg);
		-moz-transform: rotateY(180deg);
		-o-transform: rotateY(180deg);
		transform: rotateY(180deg);
	}
}
.mainflip {
	-webkit-transition: 1s;
	-webkit-transform-style: preserve-3d;
	-ms-transition: 1s;
	-moz-transition: 1s;
	-moz-transform: perspective(1000px);
	-moz-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transition: 1s;
	transform-style: preserve-3d;
	position: relative;
}
.frontside {
	position: relative;
	-webkit-transform: rotateY(0deg);
	-ms-transform: rotateY(0deg);
	z-index: 2;
	margin-bottom: 30px;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: 1s;
	-webkit-transform-style: preserve-3d;
	-moz-transition: 1s;
	-moz-transform-style: preserve-3d;
	-o-transition: 1s;
	-o-transform-style: preserve-3d;
	-ms-transition: 1s;
	-ms-transform-style: preserve-3d;
	transition: 1s;
	transform-style: preserve-3d;
	.card {
		min-height: 312px;
		.card-title {
			color: $color_2 !important;
		}
		.card-body {
			.team-img-div{
				margin: 0 auto;
				width: 120px;
				height: 120px;
			}
			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				object-fit: cover;
			}
		}
	}
}
.backside {
	position: absolute;
	top: 0;
	left: 0;
	background: white;
	-webkit-transform: rotateY(-180deg);
	-moz-transform: rotateY(-180deg);
	-o-transform: rotateY(-180deg);
	-ms-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
	-webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
	-moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
	box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: 1s;
	-webkit-transform-style: preserve-3d;
	-moz-transition: 1s;
	-moz-transform-style: preserve-3d;
	-o-transition: 1s;
	-o-transform-style: preserve-3d;
	-ms-transition: 1s;
	-ms-transform-style: preserve-3d;
	transition: 1s;
	transform-style: preserve-3d;
	.card {
		min-height: 312px;
		a {
			font-size: 18px;
			color: $color_2 !important;
		}
		.card-title {
			color: $color_2 !important;
		}
	}
}

}

// Our Team 2

.our-team-section-two{
	
/* Our Team Style Two */
$color_1: #37517e;
$color_2: #fff;
$font-family_1: "Open Sans", sans-serif;

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/

.team {
	.member {
		position: relative;
		box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
		padding: 30px;
		border-radius: 5px;
		background: #fff;
		transition: 0.5s;
		margin-bottom: 30px;

		.pic {
			overflow: hidden;
			width: 180px;
			border-radius: 50%;
			img {
				transition: ease-in-out 0.3s;
			}
		}
		&:hover {
			transform: translateY(-10px);
		}
		.member-info {
			padding-left: 30px;
		}
		h4 {
			font-weight: 700;
			margin-bottom: 5px;
			font-size: 20px;
			color: $color_1;
		}
		span {
			display: block;
			font-size: 15px;
			padding-bottom: 10px;
			position: relative;
			font-weight: 500;
			&::after {
				content: "";
				position: absolute;
				display: block;
				width: 50px;
				height: 1px;
				background: #cbd6e9;
				bottom: 0;
				left: 0;
			}
		}
		p {
			margin: 10px 0 0 0;
			font-size: 14px;
		}
		.social {
			margin-top: 12px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			a {
				transition: ease-in-out 0.3s;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50px;
				width: 32px;
				height: 32px;
				background: #eff2f8;
				text-decoration: none;
				i {
					color: $color_1;
					font-size: 16px;
					margin: 0 2px;
				}
				&:hover {
					background: #47b2e4;
					i {
						color: $color_2;
					}
				}
			}
			a+a {
				margin-left: 8px;
			}
		}
	}
}
.section-title {
	text-align: center;
	padding-bottom: 30px;
	h2 {
		font-size: 32px;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 20px;
		padding-bottom: 20px;
		position: relative;
		color: $color_1;
		&::before {
			content: "";
			position: absolute;
			display: block;
			width: 120px;
			height: 1px;
			background: #ddd;
			bottom: 1px;
			left: calc(50% - 60px);
		}
		&::after {
			content: "";
			position: absolute;
			display: block;
			width: 40px;
			height: 3px;
			background: #47b2e4;
			bottom: 0;
			left: calc(50% - 20px);
		}
	}
}
}