// Services css starts here
.services-card-section {
    margin-top: 150px;
    .card-body-style {
      background: white;
      height: 380px;
      transition: 0.6s;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
      .card-title {
        font-family: sans-serif;
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
      }
      .arrow-container {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(233, 233, 233);
        cursor: pointer;
      }
      .data-container {
        margin-top: 20px;
        padding: 0 10px;
      }
  
      &:hover {
        transition: 0.6s;
        transform: translateY(-5%);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        cursor: pointer;
  
        .card-title {
          color: #dd1d26;
        }
  
        .arrow-container {
          transition: 0.3s;
          background: #dd1d26;
          path {
            color: white;
            fill: white;
          }
        }
      }
    }
  }
  // Services css ends here