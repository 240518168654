.mainpadding{
  background-color: #F5F5F5;
}
.main-advertisement-section {
  background-color: #F5F5F5;
    .card {
      background: #ffffff;
      border-radius: 13.6003px;
      img {
        padding: 10px;
      }
      .exipry-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .expiry {
        font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #979797;
        }
        .valid {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #48bf91;
        }
        .expired {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #d44357;
        }
      }
  
      .card-title,
      h5 {
      font-family: sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 19.6233px;
        color: #000000;
      }
      p {
      font-family: sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
      .small-text {
      font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #979797;
        white-space: nowrap;
        width: 224px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .main-advertisement-section {
      margin-top: 20px;
    }
  }
  