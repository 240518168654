.second-footer-css{
    $bgColor: #ffff;
$greyCol: #757575;
$blackCol: #000;
$lightGreyCol: #e9ecef;
$primaryColor: rgba(162, 4, 1, 1);

.second-footer {
    background: $bgColor;
}

ul,
li {
    padding: 0;
    margin: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

.footer-box {
    .footer-head {
        color: $blackCol;
        text-transform: uppercase;
        margin-bottom: 2rem;
        font-weight: bold;
    }

    .footer-link-list {
        .footer-link-item {
            margin-bottom: 1rem;

            .footer-link-link {
                text-decoration: none;
                color: $greyCol;
                font-weight: 500;
                transition: 0.5s ease;

                &:hover {
                    color: $primaryColor;
                    padding-left: 0.5rem;
                }
            }
        }
    }
}

.footer-top {
    padding: 4em 0 2em 0;

    .footer-text {
        width: 50%;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .footer-widget-heading {
        h3 {
            color: $primaryColor;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 40px;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: -15px;
                height: 2px;
                width: 50px;
                background: $primaryColor;
            }
        }
    }

    .footer-widget {
        ul {
            li {
                display: inline-block;
                float: left;
                width: 50%;
                margin-bottom: 12px;

                a {
                    &:hover {
                        color: $primaryColor;
                    }

                    color: #878787;
                    text-transform: capitalize;
                }
            }
        }
    }

    .subscribe-form {
        position: relative;
        overflow: hidden;

        input {
            width: 100%;
            padding: 14px 28px;
            background: #2E2E2E;
            border: 1px solid #2E2E2f;
            color: $primaryColor;
            color: white;
        }

        button {
            position: absolute;
            right: 0;
            background: $primaryColor;
            border: 1px solid $primaryColor;
            top: 0;
            width: 13%;
            height: 100%;
            padding: 0.8em;

            svg {
                path {
                    fill: white;
                }
            }
        }
    }
}

.foooter-middle {
    border-top: 1px solid rgba($greyCol, 0.1);
    padding: 2em 0 4em 0;
}

.footer-bottom {

    .copyright-area {
        background: $lightGreyCol;
        padding: 25px 0;
    }

    .copyright-text {
        p {
            margin: 0;
            font-size: 14px;
            color: $greyCol;

            a {
                color: $primaryColor;
            }
        }
    }

    .footer-menu {
        li {
            display: inline-block;
            margin-left: 20px;

            &:hover {
                a {
                    color: $primaryColor;
                }
            }

            a {
                font-size: 14px;
                color: #878787;
            }
        }
    }
}

.social-list {
    display: flex;
    align-items: center;

    .social-item {
        margin-right: 10px;
        border-radius: 100%;

        &:last-child {
            margin-right: 0;
        }
    }
}
}