.main-productdetails-section {
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .mySwiper2 {
    height: 80%;
    width: 100%;
  }

  .mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 1;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 10px;
    }
    // img{
    //   width: 150px;
    //   height: 100px;
    // }
  }

  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;

    &:before {
      background: rgb(0 0 0 / 68%);
      position: absolute;
      z-index: 99;
      width: 100%;
      height: 100%;
      content: "";
      border-radius: 10px;
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .mySwiper .swiper-slide-active:before {
  }

  .prodcut-detailcard-slider {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    .mySwiper {
      margin-top: 20px;
    }
  }

  .product-details-section {
    border-radius: 20px;
    background-color: #ffffff;
    padding: 30px;
    // position: relative;
    height: 100%;
    .top-detail-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .product-detail-category {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #979797;
        margin: 0;
      }
      .product-detail-rating {
        margin: 0;
        span {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16.289px;
          color: #979797;
          padding-left: 3px;
        }
      }
    }

    h2 {
      font-family: sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color: #000000;
      margin-top: 10px;
    }

    .second-detail-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 18px;
      h2 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: #000000;
        margin: 0;
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #48bf91;
        margin: 0;
      }
    }

    .bottom-border-line {
      border-bottom: 1px solid rgb(173, 179, 188, 0.5);
      margin-top: 38px;
    }

    .product-detail-para {
      font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #4a4a4a;
      margin: 27px 0 0 0;
    }

    .product-select-size {
      margin-top: 30px;
      position: relative;
      .input-group {
        border: none;
        position: relative;
        .input-group-text {
          font-family: sans-serif;
          background-color: #f5f8ff;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #4a4a4a;
          padding: 24px 30px 24px 11px;

          &::after {
            content: "";
            border: 1px solid #adb3bc;
            opacity: 0.6;
            position: absolute;
            width: 2px;
            height: 35px;
            left: 118px;
          }
        }
      }
      .form-select {
        background-image: url("./images/droparrow.svg");
        cursor: pointer;
      }
      select {
        font-family: sans-serif;
        border: none;
        background-color: #f5f8ff;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;

        &:focus {
          box-shadow: none;
        }
        // &:after{
        //   background-image: url("../images/productdetails/droparrow.png");
        //   content: "";
        //   width: 14px;
        //   height: 8px;
        //   position: absolute;
        //   top: 0;
        //   z-index: 99;
        // }
      }
    }

    .product-select-color {
      margin-top: 30px;
      display: flex;
      align-items: center;
      width: 100%;
      background: #f5f8ff;
      padding: 24px 11px;
      border-radius: 10px;
      position: relative;
      p {
        margin: 0;
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #4a4a4a;
        &::after {
          content: "";
          border: 1px solid #adb3bc;
          opacity: 0.6;
          position: absolute;
          width: 2px;
          height: 35px;
          left: 118px;
          right: 0;
          top: 20px;
        }
      }
      .radio-colors {
        display: flex;
        align-items: center;
        margin-left: 26px;
        input {
          width: 20px;
          height: 20px;
          padding: 0;
          margin-left: 10px;
          border: none;
          padding: 5px;
          cursor: pointer;
          &:focus {
            border: none;
            box-shadow: none;
          }
          &:checked {
            outline: 1px solid #0076be;
            outline-offset: 2px;
          }
        }
        #darkgrey {
          background: #6d6f71;
        }
        #black {
          background: #000000;
        }
        #lightgrey {
          background: #e5e3e1;
        }
        #pink {
          background: #ecbba4;
        }
      }
    }

    .quantity-addcard {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .qunatity-div {
        display: flex;
        align-items: center;
        background: #f5f8ff;
        border-radius: 10px;
        max-width: 114.28px;
        margin-right: 30px;
        position: relative;
        input {
          width: 114.28px;
          background: transparent;
          text-align: center;
          font-family: sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #000000;
          &::placeholder {
            text-align: center;
            font-family: sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
          }
        }
        .arrow-down-icon {
          left: 17px;
          position: absolute;
          cursor: pointer;
        }
        .arrow-up-icon {
          position: absolute;
          right: 17px;
          cursor: pointer;
        }
        svg {
        }
      }

      button {
        background: #0076be;
        border-radius: 10px;
        // padding: 16px 0;
        height: 55px;
        line-height: 55px;
        padding: 0;
        span {
          padding-left: 9px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .product-detail-col-right {
    width: 50%;
  }
  .product-details-section {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .product-details-section {
    margin-top: 20px;
  }
}

/* Shopping Cart */
.pl-0 {
  padding-left: 0 !important;
}

thead {
  tr {
  }
}

.main-cart-table-section {
  .cart-table {
    background: #ffffff !important;
    border-radius: 20px;
    padding: 20px 20px 20px 20px;
    height: 557px;
    table {
      thead {
        background: #0076be;
        border-radius: 20px;

        tr {
          th {
            border-style: none;
            font-family: sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #ffffff;
            padding-top: 17px;
            padding-bottom: 17px;
          }
          th:first-child {
            border-top-left-radius: 10px !important;
            border-bottom-left-radius: 10px !important;
          }
          th:last-child {
            border-top-right-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
          }
          th:not(:first-child) {
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          td {
            vertical-align: middle;
            text-align: center;
          }
        }
      }
    }
    .order-img-title {
      display: flex;
      align-items: center;
      h2 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #000000;
        padding-left: 55px;
        margin: 0;
      }
    }
    .quantity {
      p {
        margin: 0;
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #979797;
      }
    }
    .price {
      p {
        margin: 0;
        font-family: sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #000000;
      }
    }
    .total {
      p {
        margin: 0;
        font-family: sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #000000;
      }
    }
    .edit-delete-btns {
      a:first-child {
        margin-right: 23px;
      }
    }
  }

  .amount-details {
    background: #ffffff !important;
    border-radius: 20px;
    padding: 20px 20px 20px 20px;
    h1 {
      font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      border-bottom: 1px solid rgb(173, 179, 188, 0.5);
      padding-bottom: 24px;
    }
    .checkout-total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 35px;
      margin-top: 29px;
      p {
        margin: 0;
        font-family: sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: #000000;
        background: #f5f8ff;
        border-radius: 10px;
        /* padding: 19px 8px; */
        /* width: 45%; */
        width: 248px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        margin-right: 40px;
        span {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #979797;
        }
      }
      a {
        background: #0076be;
        border-radius: 10px;
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        /* padding: 19px 8px; */
        /* width: 45%; */
        width: 248px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        span {
          margin-right: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .cart-table > .table {
    min-width: 700px;
  }
  .cart-table {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    will-change: unset;
  }
  ::-webkit-scrollbar {
    height: 4px; /* height of horizontal scrollbar ← You're missing this */
    width: 4px; /* width of vertical scrollbar */
    border: 1px solid #d5d5d5;
  }
  .main-cart-table-section .amount-details .checkout-total p {
    font-size: 18px;
  }
  .main-cart-table-section .amount-details .checkout-total a {
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) {
  .main-cart-table-section .amount-details .checkout-total {
    display: block;
  }
  .main-cart-table-section .amount-details .checkout-total p {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .main-cart-table-section .amount-details .checkout-total a {
    width: 100%;
    text-align: center;
    display: block;
  }
  .cart-table-div.col-xxl-8.col-md-7 {
    width: 100%;
    margin-bottom: 20px;
  }
}

/* Delete Process */
.modal-content {
  background-color: transparent;
  border: none;
}
.delete-popup {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 586px;
  height: 350px;
  margin: auto auto;

  .bottom-border {
    border-bottom: 1px solid rgb(173, 179, 188, 0.5);
  }

  h1 {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #000000;
    padding: 25px 0;
  }

  p {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #000000;
    margin-top: 29px;
  }
  .yes-btn {
    font-family: sans-serif;
    background: #0076be;
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    padding: 15px 94px;
  }
  .no-btn {
    font-family: sans-serif;
    background: #ffffff;
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #adb3bc;
    padding: 15px 94px;
  }
  .btn-div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 80px;
  }
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .delete-popup {
    width: 100%;
    height: 350px;

    .yes-btn {
      font-size: 18px;
      padding: 10px 50px;
    }
    .no-btn {
      font-size: 18px;
      padding: 10px 50px;
    }
  }
}

/* Payment Method */
.main-payment-section {
  .selectbrand-section {
    background: #ffffff;
    border-radius: 20px;
    padding: 26px 30px;
    .input-group {
      padding: 14px 0;

      select {
        border: none;
        border-left: 1px solid rgba(173, 179, 188, 0.5);
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        text-overflow: ellipsis !important;
        cursor: pointer;
        &:focus {
          box-shadow: none;
        }
      }
      span {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #4a4a4a;
      }
    }
  }

  .shipping-address-section {
    background: #ffffff;
    border-radius: 20px;
    margin-top: 30px;
    padding-left: 24px;
    padding-right: 24px;
    h6 {
      font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      padding: 25px 0;
      border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    }
    p {
      padding: 0;
      margin: 0;
    }
    .address {
      border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      padding: 14px 0;

      p {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        line-height: 32px;
      }
    }
    .add-new-address {
      padding: 28px 0;
      svg {
        margin-right: 5px;
      }
    }
  }

  .order-details {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    h6 {
      font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      padding: 25px 0;
      border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    }

    .inner-order-details {
      .card-details-div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
        }
        .price {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #000000;
        }
      }
    }
  }
  .select-bank-card {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 30px;
    h6 {
      font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      padding: 25px 0;
      border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    }
    .strip-checkmark-div {
      border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      .form-check {
        margin: 40px 0;
        width: 115px;
      }
      input {
        width: 25px;
        height: 30px;
        border-radius: 5px;
        margin-right: 10px;
        cursor: pointer;
        &:focus {
          box-shadow: none;
        }
      }
      label {
        cursor: pointer;
        margin-top: 3px;
      }
    }
    .add-new-address {
      padding: 28px 0;
      svg {
        margin-right: 5px;
      }
    }
  }
  .payment-summary {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    h6 {
      font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      padding: 25px 0;
      border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    }

    .inner-order-details {
      .card-details-div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
        }
        .price {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #000000;
        }
      }
    }
  }
  .place-order {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 30px;
    h6 {
      font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      padding: 25px 0;
      border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    }
    .total-price-div {
      margin-top: 34px;

      p {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        color: #000000;

        span {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #979797;
        }
      }
      input {
        width: 25px;
        height: 25px;
        border-radius: 5px;
        margin-right: 10px;
        cursor: pointer;
      }
      label {
        cursor: pointer;
      }
    }
    .add-new-address {
      padding: 28px 0;

      svg {
        margin-right: 5px;
      }
      button {
        background: #0076be;
        border-radius: 10px;
      }
      .btn-width {
        width: 248px;
        height: 55px;
      }
    }
  }
}

@media screen and (min-width: 1700px) and (max-width: 1920px) {
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .main-payment-section {
    .selectbrand-section {
      background: #ffffff;
      border-radius: 20px;
      padding: 26px 30px;
      .input-group {
        padding: 14px 0;

        select {
          border: none;
          border-left: 1px solid rgba(173, 179, 188, 0.5);
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;

          &:focus {
            box-shadow: none;
          }
        }
        span {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #4a4a4a;
        }
      }
    }

    .shipping-address-section {
      background: #ffffff;
      border-radius: 20px;
      margin-top: 30px;
      padding-left: 24px;
      padding-right: 24px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      p {
        padding: 0;
        margin: 0;
      }
      .address {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        padding: 14px 0;

        p {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          line-height: 32px;
        }
      }
      .add-new-address {
        padding: 28px 0;
        svg {
          margin-right: 5px;
        }
      }
    }

    .order-details {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }

      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
          }
          .price {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
          }
        }
      }
    }
    .select-bank-card {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .strip-checkmark-div {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        .form-check {
          margin: 40px 0;
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
        padding: 28px 0;
        svg {
          margin-right: 5px;
        }
      }
    }
    .payment-summary {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 20px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }

      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
          }
          .price {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
          }
        }
      }
    }
    .place-order {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .total-price-div {
        margin-top: 34px;

        p {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          color: #000000;

          span {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #979797;
          }
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
        padding: 28px 0;

        svg {
          margin-right: 5px;
        }
        button {
          background: #0076be;
          border-radius: 10px;
        }
        .btn-width {
          width: 248px;
          height: 55px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .main-payment-section {
    .selectbrand-section {
      background: #ffffff;
      border-radius: 20px;
      padding: 26px 30px;
      .input-group {
        padding: 14px 0;

        select {
          border: none;
          border-left: 1px solid rgba(173, 179, 188, 0.5);
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;

          &:focus {
            box-shadow: none;
          }
        }
        span {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #4a4a4a;
        }
      }
    }

    .shipping-address-section {
      background: #ffffff;
      border-radius: 20px;
      margin-top: 30px;
      padding-left: 24px;
      padding-right: 24px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      p {
        padding: 0;
        margin: 0;
      }
      .address {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        padding: 14px 0;

        p {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          line-height: 32px;
        }
      }
      .add-new-address {
        padding: 28px 0;
        svg {
          margin-right: 5px;
        }
      }
    }

    .order-details {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }

      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
          }
          .price {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
          }
        }
      }
    }
    .select-bank-card {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .strip-checkmark-div {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        .form-check {
          margin: 40px 0;
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
        padding: 28px 0;
        svg {
          margin-right: 5px;
        }
      }
    }
    .payment-summary {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 20px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }

      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
          }
          .price {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
          }
        }
      }
    }
    .place-order {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .total-price-div {
        margin-top: 34px;

        p {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          color: #000000;

          span {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #979797;
          }
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
        padding: 28px 0;

        svg {
          margin-right: 5px;
        }
        button {
          background: #0076be;
          border-radius: 10px;
        }
        .btn-width {
          width: 248px;
          height: 55px;
        }
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .main-payment-section {
    .selectbrand-section {
      background: #ffffff;
      border-radius: 20px;
      padding: 26px 30px;
      .input-group {
        padding: 14px 0;

        select {
          border: none;
          border-left: 1px solid rgba(173, 179, 188, 0.5);
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;

          &:focus {
            box-shadow: none;
          }
        }
        span {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #4a4a4a;
        }
      }
    }

    .shipping-address-section {
      background: #ffffff;
      border-radius: 20px;
      margin-top: 30px;
      padding-left: 24px;
      padding-right: 24px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      p {
        padding: 0;
        margin: 0;
      }
      .address {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        padding: 14px 0;

        p {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          line-height: 32px;
        }
      }
      .add-new-address {
        padding: 28px 0;
        svg {
          margin-right: 5px;
        }
      }
    }

    .order-details {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 20px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }

      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
          }
          .price {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
          }
        }
      }
    }
    .select-bank-card {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .strip-checkmark-div {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        .form-check {
          margin: 40px 0;
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
        padding: 28px 0;
        svg {
          margin-right: 5px;
        }
      }
    }
    .payment-summary {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 20px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }

      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
          }
          .price {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
          }
        }
      }
    }
    .place-order {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .total-price-div {
        margin-top: 34px;

        p {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          color: #000000;

          span {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #979797;
          }
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
        padding: 28px 0;

        svg {
          margin-right: 5px;
        }
        button {
          background: #0076be;
          border-radius: 10px;
        }
        .btn-width {
          width: 248px;
          height: 55px;
        }
      }
    }
  }
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .main-payment-section {
    .selectbrand-section {
      background: #ffffff;
      border-radius: 20px;
      padding: 15px 10px;
      .input-group {
        padding: 14px 0;

        select {
          border: none;
          border-left: 1px solid rgba(173, 179, 188, 0.5);
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #000000;

          &:focus {
            box-shadow: none;
          }
        }
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }

    .shipping-address-section {
      background: #ffffff;
      border-radius: 20px;
      margin-top: 30px;
      padding-left: 24px;
      padding-right: 24px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      p {
        padding: 0;
        margin: 0;
      }
      .address {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        padding: 14px 0;

        p {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #000000;
          line-height: 32px;
        }
      }
      .add-new-address {
        padding: 28px 0;
        svg {
          margin-right: 5px;
        }
      }
    }

    .order-details {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 20px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }

      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #000000;
          }
          .price {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #000000;
          }
        }
      }
    }
    .select-bank-card {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .strip-checkmark-div {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        .form-check {
          margin: 40px 0;
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
        padding: 28px 0;
        svg {
          margin-right: 5px;
        }
      }
    }
    .payment-summary {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 20px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }

      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #000000;
          }
          .price {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #000000;
          }
        }
      }
    }
    .place-order {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .total-price-div {
        margin-top: 34px;

        p {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          color: #000000;

          span {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #979797;
          }
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
        padding: 28px 0;

        svg {
          margin-right: 5px;
        }
        button {
          background: #0076be;
          border-radius: 10px;
        }
        .btn-width {
          width: 248px;
          height: 55px;
        }
      }
    }
  }
}

/* Order Placed Modal */
.confirmed-order {
    .confirmed-order-container {
      background: #ffffff;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      padding-right: 24px;
      padding-left: 24px;
      padding-bottom: 54px;
      max-width: 586px;
      margin: 0 auto;
  
      .heading-div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgb(173, 179, 188, 0.5);
        h1 {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          text-align: center;
          color: #000000;
          padding: 25px 0;
          margin: 0 auto;
        }
        svg{
          cursor: pointer;
        }
      }
  
      .order-svg {
        margin: 20px 0;
        svg {
          display: block;
          margin: 0 auto;
          text-align: center;
        }
      }
  
      .middle-text {
        p {
          margin: 0;
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          text-align: center;
          color: #000000;
        }
      }
      .small-para {
        margin: 0;
      font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #4a4a4a;
        margin-bottom: 25px;
  
      }
      a {
        background: #0076be;
        border-radius: 10px;
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
        /* margin-top: 25px; */
        transition: 0.2s ease-in;
        /* padding: 14px 0; */
        display: block;
        max-width: 432px;
        height: 55px;
        line-height: 55px;
        margin: 0 auto;
        &:hover {
          background: #ffffff;
          color: #0076be;
          border: 1px solid #0076be;
        }
      }
    }
  }
  