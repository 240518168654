.tiles {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12.1869px;
  padding: 20px;
  height: 100px;
  cursor: pointer;
  transition: 0.6s;


  .icon-container {
    background: rgba(0, 112, 192, 0.2);
    border-radius: 6px;
    width: 38px;
    height: 38px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .upper-heading {
    /* Title Data */
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.594382px;
    color: #9e9e9e;
  }

  .lower-heading {
    /* Title Data */
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.594382px;
    color: #000000;
  }

  &:hover{
    transition: 0.6s;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      transform: translateY(-10%);
  }
}
