.main-newsletter-section {
  margin: 40px auto;
  border-radius: 20px;
  padding: 60px 0;
  background-color: rgb(199, 199, 199);
  width: 80%;
  h2 {
    font-size: 40px;
    text-align: center;
  }
  p {
    font-size: 18px;
    text-align: center;
  }
  .email-div {
    form {
      display: flex;
      justify-content: center;

      input {
        width: 50%;
        border-radius: 10px;
        height: 50px;

        &:focus {
          box-shadow: none;
        }
        &::placeholder {
          color: rgb(206, 206, 206);
        }
      }
      button {
        margin-left: 20px;
        max-width: 40px;
        margin-left: 20px;
        max-width: 125px;
        width: 100%;
        border-radius: 10px;
        border-radius: 10px;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  .privacy {
    margin-top: 10px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1700px) {
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
}

@media screen and (min-width: 768px) and (max-width: 992px) {
}

@media screen and (min-width: 576px) and (max-width: 768px) {
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .main-newsletter-section .email-div form button {
    margin-left: 20px;
    max-width: 40px;
    margin-left: auto;
    max-width: 125px;
    width: 100%;
    border-radius: 10px;
    border-radius: 10px;
    margin-top: 10px;
    display: block;
  }

  .main-newsletter-section .email-div form input {
    width: 100%;
    border-radius: 10px;
    height: 50px;
  }

  .main-newsletter-section .email-div form {
    display: block;
    /* justify-content: center; */
    margin: 0 auto;
  }

  .main-newsletter-section h2 {
    font-size: 20px;
    text-align: center;
  }

  .main-newsletter-section .privacy {
    margin-top: 20px;
    font-size: 12px;
  }
}
