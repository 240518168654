// Accordion Section Starts Here
.accordion-section {
    .container{
        max-width: 1440px;
    }
  background: #f6f9fe;
  height: 100vh;
  margin-top: 100px;

  .accordion-main-heading {
    color: black;
    font-family: sans-serif;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    margin-top: 30px;
  }

  .accordion-para {
    font-size: 24px;
    color: #7e7e7e;
    font-weight: 400;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 50px;
  }

  .accordion{
    margin-top: 50px;
    .accordion-button:not(.collapsed) {
        background: linear-gradient(101.31deg, #ff869f 2.11%, #fa988a 34.14%, #f19a73 68.81%, #ffd0b1 105.05%);
        box-shadow: 0px 9px 20px rgb(0 0 0 / 10%);  
        color: white;
    }

    .accordion-item{
        border: none;
        margin-bottom: 20px;
    }

    .accordion-body{
        background: #f6f9fe;
        padding: 20px 20px 10px 20px;
        font-size: 16px;
        color: gray;
    }
    .accordion-button{
        font-weight: 600;
        font-size: 20px;
        &:focus{
            box-shadow: none;
            outline: none;
            border: none;
            border: 0!important;
        }
    }
  }
}
// Accordion Section Ends Here