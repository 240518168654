.sidebar{
    background: rgb(0, 0, 56);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 100vh;
    width: 550px;
    padding: 40px;
    position: relative;
    transition: .4s ease-in-out;
    padding-left: 10px;


    .backarrow-container{
        background: rgb(126, 126, 126);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        right: -12px;
        top: 30px;
        cursor: pointer;
    }

    .sidebar-container{
        ul{
            li{
                color: white;
                list-style: none;
                text-decoration: none;
                cursor: pointer;
                margin-bottom: 20px;
                // display: flex;
                // align-items: center;
                transition: .3s ease-in-out;


                span{
                    padding-left: 10px;
                    font-size: 14px;
                    transition: 0.3s ease-in;
                    

                }
            }
        }
    }
}

.sidebar-collapse{
    background: rgb(0, 0, 56);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 100vh;
    width: 100px;
    padding: 40px;
    position: relative;
    transition: 0.3s ease-in;
    padding-left: 10px!important;

    .backarrow-container{
        background: rgb(126, 126, 126);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        right: -12px;
        top: 30px;
        cursor: pointer;
    }

    .sidebar-container{
        ul{
            li{
                color: white;
                list-style: none;
                text-decoration: none;
                cursor: pointer;
                margin-bottom: 20px;
                display: flex;
                align-items: center;

                span{
                    padding-left: 10px;
                    font-size: 14px;
                    display: none;
                    transition: 0.3s ease-in;
                }
            }
        }
    }
}

.right-section{
    margin-left: 100px;

}