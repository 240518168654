.product-chart-section {
    padding: 150px 0;
    overflow: hidden;

    @media (max-width: 992px) {
        padding: 0;
        height: 100vh;
    }
}

.product {
    display: flex;
    flex-wrap: wrap;
    background: #faf3f357;
    border-radius: 20px;
    padding: 40px;

    @media (max-width: 992px) {
        padding: 0;
    }

    .gallery {
        width: 50%;
        position: relative;
        z-index: 1;

        @media (max-width: 992px) {
            width: 100%;
        }

        &::before {
            content: '';
            position: absolute;
            top: -140px;
            right: -120px;
            width: 480px;
            height: 480px;
            border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
            background: radial-gradient(at top right, rgba(209, 98, 52, 0.33), #d16234);
            animation: 12s goop ease-in-out infinite both alternate;

            @keyframes goop {
                0% {
                    border-radius:
                        40% 60% 60% 40% e('/') 60% 30% 70% 40%;
                    transform: rotate(0deg)
                }

                100% {
                    border-radius: 40% 60%;
                    transform: rotate(1turn)
                }
            }

            @media (max-width: 1024px) {
                top: -80px;
                right: 40px;
                width: 300px;
                height: 300px;
            }
        }

        img {
            width: 140%;
            top: -10%;
            left: -20%;
            transform:
                rotate(-22deg) scalex(-1);
            position: relative;

            @media (max-width: 1024px) {
                width: 80%;
                top: 0;
                left: 0;
            }
        }

        .price {
            position: absolute;
            background: #1d3b43;
            color: white;
            font-size: 24px;
            font-weight: bold;
            width: 110px;
            height: 40px;
            line-height: 40px;
            top: 106px;
            left: -65px;
            transform: rotate(0deg);
            text-align: right;
            border-radius: 4px 0 0 4px;

            @media (max-width: 992px) {
                left: 0;
            }

            @media (max-width: 767px) {
                padding: 0 10px;
                width: auto;
                font-size: 15px;
                top: 0;
            }

            span {
                opacity: 0.5
            }

            &::before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-bottom: 20px solid transparent;
                border-top: 20px solid transparent;
                border-left: 20px solid #1d3b43;
                right: -20px;
            }
        }
    }

    .details {
        width: 50%;
        position: relative;

        @media (max-width: 992px) {
            width: 100%;
        }

        h1 {
            margin: 0;
            font-size: 56px;

            @media (max-width: 1024px) {
                font-size: 36px;
            }
        }

        p {
            width: 40ch
        }

        .sizeTable {
            display: flex;
            flex-flow: row wrap;
            width: 40ch;
            margin: 50px 0 40px;
            position: relative;
            z-index: 2;

            @media (max-width: 992px) {
                padding-bottom: 5em;
            }

            h3 {
                flex: 1 1 100%;
                margin: 0 0 25px 0px;
                text-transform: uppercase;
                letter-spacing: 2px;
                font-size: 80%;
                line-height: 0;
                transition: 400ms margin 0ms;

                span {
                    display: inline-block;
                    max-width: 200px;
                    max-height: 200px;
                    opacity: 1;
                    transition:
                        400ms max-width 0ms,
                        400ms max-height 0ms,
                        400ms opacity 400ms;

                    &:nth-of-type(2) {
                        color: lighten(#d16234, 30%)
                    }
                }
            }

            button {
                display: inline;
                width: 50px;
                height: 50px;
                margin: 0 10px 10px 0;
                padding: 0;
                border-radius: 50px;
                background: $paleWhite;
                text-align: center;
                line-height: 50px;
                opacity: 1;
                overflow: hidden;
                border: 0;
                transition: 0.5s ease;

                &:hover {
                    color: $white;
                    background: $orange
                }
            }
        }

        .callsToAction {
            display: flex;
            position: relative;
            z-index: 3;
            opacity: 0;
            max-height: 0;
            top: -60px;
            left: 60px;
            margin-bottom: 0;
            transition: 0.5s ease;

            button {
                height: 50px;
                padding: 0 20px;
                background: $paleWhite;
                border-radius: 50px;
                margin-right: 10px;
                transition: 0.5s ease;
                border: 0;

                &:hover {
                    color: $white;
                    background: $orange
                }
            }
        }
    }

    .size-table {
        button {
            font-weight: bold;
            text-transform: uppercase;
            cursor: pointer
        }
    }
}

//When a size is selected, do this:
.sizeSelected {
    .product {
        .details {
            .sizeTable {
                h3 {
                    margin: 0 0 25px 8px;
                    transition: 400ms margin 200ms;

                    span {
                        max-width: 0;
                        max-height: 0;
                        opacity: 0;
                        transition:
                            400ms max-width 400ms,
                            400ms max-height 400ms,
                            400ms opacity 0ms;
                    }
                }

                button {
                    margin: 0;
                    border: none;
                    box-shadow: none;
                    transform: scale(0);
                    opacity: 0;
                    display: none;

                    &.selectedSize {
                        box-shadow: inset 0 0 50px 1px #d16234;
                        border: 1px solid #d16234;
                        color: white;
                        transform: scale(1);
                        opacity: 1;
                        display: block
                    }
                }
            }

            .callsToAction {
                opacity: 1;
                max-height: 200px;
                max-width: 300px;
                top: -90px;
                margin-bottom: 80px;
                transition:
                    400ms top 800ms,
                    400ms opacity 800ms;
            }
        }
    }
}