/*** First Banner CSS ***/
.firstBannerSection {
    height: 100vh;

    @media (max-width: 767px) {
        height: 60vh;
    }
}

.bannerFirst {
    height: 100%;

    .carousel-inner {
        height: 100%;
    }

    .carousel-item {
        height: 100%;
        position: relative;

        @media (max-width: 767px) {
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(#000, 0.4);
            }
        }

        img {
            @media (max-width: 767px) {
                object-fit: cover;
                object-position: right;
            }
        }

        .bannerCaption {
            top: 50%;
            left: 15%;
            right: auto;
            bottom: auto;
            transform: translate(0, -50%);
            text-align: left;
            width: 40em;
            z-index: 5;

            @media (max-width: 767px) {
                width: 74%;
                display: flex;
                flex-flow: column;
            }

            &_firstBanner {
                span {
                    display: block;
                    font-size: 62px;
                    color: #ff866a; // Add the color regarding to the design
                    font-weight: bold;
                    line-height: 1;
                    transition: 1.1s ease;
                }

                small {
                    font-size: 26px; // Add the font size according to the design 
                    line-height: 1;
                    text-transform: uppercase;
                    display: inline-block;
                    transition: 1.3s ease;

                    @media (max-width: 767px) {
                        font-size: 18px;
                    }
                }

                h1 {
                    text-transform: uppercase;
                    transition: 1.4s ease;
                    font-size: 48px; // Add the font size according to the design 

                    @media (max-width: 767px) {
                        font-size: 36px;
                    }
                }

                p {
                    transition: 1.1s ease;
                    font-size: 17px; // Add the font size according to the design 
                }

                .btn {
                    transition: 1.2s ease;
                }

                span,
                small,
                h1,
                p,
                .btn {
                    transform: translateY(100px);
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        &.active {
            .bannerCaption {
                &_firstBanner {

                    span,
                    small,
                    h1,
                    p,
                    .btn {
                        transform: translateY(0);
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

        }
    }
}



/** Global Carousel **/

.carousel {
    .carousel-indicators [data-bs-target] {
        width: 8px;
        height: 8px;
        border-radius: 100%;
    }
}

/** Second Banner CSS **/
.second-banner-section {
    background-color: #f3f3f3;
    padding: 80px 0;
    overflow: hidden;

    .bannerSecond {
        .bannerCaption {
            &_secondBanner {
                color: #363F4D;
                top: 2%;
                left: 4%;
                bottom: auto;
                text-align: left;

                span {
                    transition: 1.1s ease;
                }

                small {
                    transition: 1.3s ease;
                }

                h1 {
                    transition: 1.4s ease;
                }

                p {
                    transition: 1.1s ease;
                }

                .btn {
                    transition: 1.2s ease;
                }

                span,
                small,
                h1,
                p,
                .btn {
                    transform: translateX(-100px);
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        .carousel-inner {
            height: 505px;
        }

        .title {
            font-weight: 700;
            font-size: 50px;
        }

        .price {
            span {
                font-weight: 600;
                font-size: 38px;
            }
        }

        .carousel-item {
            // img {
            //     transform: scale(1.5);
            //     transition: 1.5s linear;
            //     opacity: 0;
            // }

            &.active {
                img {
                    transform: scale(1);
                    opacity: 1;
                }

                .bannerCaption {
                    &_secondBanner {

                        span,
                        small,
                        h1,
                        p,
                        .btn {
                            transform: translateX(0);
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }

    .bannerCard-wrapper {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        height: 505px;
        width: 100%;

        .banner-card {
            &__first {
                height: 70%;
                width: 100%;
                @media (max-width: 992px) {
                    padding: 10px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &__second {
                height: 30%;
                width: 100%;
                @media (max-width: 992px) {
                    padding: 10px;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    //carousel dot : This can be style styled accordingly to the design
    .carousel-indicators {
        button {
            background-color: #c4c4c4;

            &.active {
                background-color: $red;
            }
        }
    }

    //Carousel Navigation: Display block/none regarding to the needs
    .carousel-control-prev,
    .carousel-control-next {
        display: none;
    }

}