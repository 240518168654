.scrollable-tabs{
    .swiper{
        width: 50%;
        padding: 20px;
        .tabs{
            background: blue;
            color: white;
            text-align: center;
            font-weight: 700;
            margin: 30px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            cursor: pointer;

        }


    }
}
.tabs-content{
    color: blue;
    font-weight: 800;
    font-size: 40px;
    text-align: center;
}

@media (max-width:1400px){
    .scrollable-tabs{
        .swiper{
            width: 80%;
        }
    }
}

@media (max-width:100px){
    .scrollable-tabs{
        .swiper{
            width: 100%;
        }
    }
}