.table-container{
    width: 100%;
    background: white;
    height: 100%;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 20px;
    margin-top: 50px;

    .datagrid-table.MuiDataGrid-root.MuiDataGrid-root--densityStandard.css-1e2bxag-MuiDataGrid-root{
        border: none;
    }

    .MuiDataGrid-cell:focus {
        outline: none!important;
    }


    .user-button {
        button#dropdown-basic-button {
          width: 30px;
          height: 30px;
          background: #fafafb;
          box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
          border-radius: 8px;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:focus {
            border: none;
          }
        }
      
        .dropdown-toggle::after {
          display: none;
        }
      }

      .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
        overflow: visible;
        white-space: nowrap;
    }
    
}


.delete-modal {
    .modal-content {
      max-width: 431px;
      height: 225px;
      border-radius: 10px;
      background: white;
      padding: 32px;
      margin: 0 auto;
    }
  
    .main-heading {
      /* Info */
      font-family: sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 150%;
      color: #000000;
    }
  
    p {
      margin-top: 32px;
      /* Content */
      font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.15px;
      /* Grey */
      color: #707581;
      margin-bottom: 20px;
    }
  
    .main-btn {
      width: 160px;
      height: 40px;
      background: black;
      color: white;
      font-family: sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      border-radius: 20px;
      border: 1px solid black;
      cursor: pointer;
      &:focus,
      &:hover {
        background: black !important;
        box-shadow: none;
        border: 1px solid black;
        color: white !important;
      }
    }
  
    .sub-btn {
      width: 100px;
      height: 40px;
      border: none;
      box-shadow: none;
      background: transparent;
      /* Button */
  
      font-family: "Ageo";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
      display: flex;
      align-items: center;
      text-transform: capitalize;
      justify-content: center;
  
      /* Black */
      color: #000000;
      &:focus,
      &:hover {
        background: transparent !important;
        /* Black */
        color: #000000 !important;
        border: none;
        box-shadow: none !important;
        outline: none !important;
      }
    }
  }