@import "./abstracts/_theme";

@import "./components/_components";

@import "./layouts/_layout";

@import "./pages/pages";

@import "./media_queries/_main-media-query";

@import "../../components/Tiles/Tiles";

@import "../../components/Tiles2/Tiles2.scss";

@import "../../components/Table/Table.scss";

@import "../../components/Sidebar/Sidebar";

@import "../../components/NewSidebar/NewSidebar";

@import "../../components/Button/Button";

@import "../../components/BaileyComponents/BaileyComponents.scss";

@import "../../components/Home/Home.scss";

@import "../../components/ProductCard/ProductCard";

@import "../../components/ProductCard/ProductCardTwo";

@import "../../components/TypewriteBanner/Typewriter-banner";

@import "../../components/AccordionSection/AccordionSection";

@import "../../components/ReactCounter/React-Counter";

@import "../../components/ReactCounterCards/ReactCounterCards.scss";

@import "../../components/ScrollableTabs/ScrollableTabs";

@import "../../components/ParticlesBg/particles";

@import "../../components/DashboardPopup/Dashboard-Poup";

@import "../../components/ServicesCard/ServicesCard";

@import "../../components/notificationmodal/notification";
@import "../../components/sidemenu/sidemenu";
@import "../../components/sidemenu/advertisement";
@import "../../components/sidemenu/editprofile";
@import "../../components/sidemenu/orderreview";
@import "../../components/sidemenu/settingsidemenu";
@import "../../components/sidemenu/changepassword";
@import "../../components/sidemenu/termspage";
@import "../../components/sidemenu/privacypage";
@import "../../components/sidemenu/logoutmodal";
@import "../../components/checkoutprocess/checkoutprocess";
@import "../../components/settingscomp/settingcomp";
@import "../../components/searchmodal/searchcommodal";
@import "../scss/components/searchbar";
@import "../../components/DashboardSidebar/DashboardSIdebar";
@import "../../components/AntdTable/AntdTable.scss";
@import "../../components/AntdSubTable/AnrdSubTable.scss";
@import "../../components/ExpandableSearch/ExpandableSearch";
@import "../../components/SigninPageSyntax/SigninSyntax";
@import "../../components/SelectRole/SelectRole";
@import "../../components/ToastContainer/Toaster.scss";

.try{
  height: 1000px;
  background: black;
}

// .bg-img-auth {
//     background-image: url("../images/loginbannerside.png");
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-position: center center;
//   }


::-webkit-scrollbar {
  width: 15px;
  // display: none;
}



/*
********************************************
* Number Input Style
********************************************
*/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
// input[type="number"] {
//   -moz-appearance: textfield;
// }

/*
********************************************
* Global Style
********************************************
*/

// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// font-family: sans-serif;
// }
// dl,
// ol,
// ul {
//   list-style: none;
//   margin: 0px;
//   padding: 0px;
// }

.text-red {
  color: red;
  font-size: 12px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.btn {
  box-shadow: none !important;

  &:hover,
  &:focus {
    box-shadow: none !important;
  }
}

input,
.form-control {
  font-size: 12px;
  height: 55px;
  border-radius: 10px;
  padding: 0 1rem;
  border: none;
  box-shadow: none;
  outline: none;

  &::placeholder {
    color: gray;
    font-weight: 300;
  }

  &:hover,
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.input-group {
  border-radius: 10px;
  border: 1px solid gray;
  transition: all 0.5s ease;

  &:hover,
  &:focus {
    border: 1px solid gray;
  }

  .input-group-text {
    background-color: white;
    border: none;
    border-radius: 10px;
  }
}

/*
********************************************
* Theme Font Size, Family And Weight 
********************************************
*/

// h2,
// .h2 {
//   font-size: 36px;
//   font-weight: 600;
// }

// h4,
// .h4 {
//   font-size: 24px;
//   font-weight: 500;
// }

// h5,
// .h5 {
//   font-size: 20px;
//   font-weight: 500;
// }

// h6,
// .h6 {
//   font-size: 18px;
//   font-weight: 400;
// }

// p,
// span,
// .form-label {
//   font-size: 14px;
//   font-weight: 400;
// }

// a,
// .nav-link {
//   font-size: 16px;
//   font-weight: 400;
//   text-decoration: none;
// }

small {
  font-size: 12px;
  font-weight: 400;
}

ul {
  li {
    font-size: 12px;
  }
}

.button,
a.button,
button.button {
  &.btn-solid-blue {
  font-family: sans-serif;
    max-width: 430px;
    border-radius: 10px;
    border: 2px solid transparent;
    background-color: #000;
    outline: none;
    box-shadow: none;
    transition: all 0.5s ease;
    background-color: #0076be;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    height: 55px;
    line-height: 50px;


    &:hover,
    &:focus,
    &:focus-within {
      color: black;
      background: transparent;
      border: 2px solid blue;
      color: blue;
      box-shadow: none;
      outline: none;
    }
  }

  &.btn-outline-blue {
    border: 2px solid blue;
    padding: 15px 0;
    border-radius: 10px;

    box-shadow: none;
    transition: all 0.5s ease;

    &:hover,
    &:focus,
    &:focus-within {
      background-color: blue;
      box-shadow: none;
      outline: none;
    }
  }

  &.btn-solid-green {
  font-family: sans-serif;
    border-radius: 10px;
    max-width: 430px;
    border: 2px solid transparent;
    background-color: #000;
    outline: none;
    box-shadow: none;
    transition: all 0.5s ease;
    background-color: green;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    height: 55px;
    line-height: 50px;

    &:hover,
    &:focus,
    &:focus-within {
      color: black;
      background: transparent;
      border: 2px solid green;
      color: green;
      box-shadow: none;
      outline: none;
    }
  }

  &.btn-outline-green {
    border: 2px solid green;
    padding: 15px 0;
    border-radius: 10px;

    box-shadow: none;
    transition: all 0.5s ease;

    &:hover,
    &:focus,
    &:focus-within {
      background-color: green;
      box-shadow: none;
      outline: none;
    }
  }
}


button.button {
    &.btn-solid-blue {
        line-height: unset;
    }
}


.home-filter-button {
  text-decoration: none;
  color: #fff;
  background: #0076be;
  font-family: sans-serif;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 10px;
  /* padding: 10px 15px; */
  border-color: #0076be;
  border: 0;
  float: right;
  // margin: 18px 0 0 0;
  text-decoration: none;
  width: 126px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  svg{
    margin-right: 10px;
  }
  &:hover{
    color:#fff;
  }
}

.margin-top-40{
  margin-top: 40px;
}

.margin-top-32{
  margin-top: 32px;
}

.margin-right-30{
  margin-right: 30px !important;
}



  a{
    text-decoration: none;
  }
  input{
    border-bottom: 1px solid blue;
    border: none;
  }
  .continue-wrapper {
    margin: 40px 0;
  }
  .authbodyarea {
    background-color: #f5f8ff;
    height: 100vh;
  
    .bagimg {
      width: 100%;
      height: 100%;
    }
  
    .bagimg img {
      height: 100%;
      width: 100%;
    }
  
    .authbbox {
      padding: 30px 50px;
      background: #fff;
      width: 80%;
      margin: 0 auto;
      text-align: center;
      border-radius: 25px;
      font-family: sans-serif;
      max-width: 580px;
      .file-upload {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
      }
      .img-upload {
        cursor: pointer;
      }
      > h2 {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        padding-bottom: 20px;
        margin-bottom: 30px;
        border-bottom: 1px solid #141c4a20;
      }
  
      > p.para {
        font-size: 18px;
        font-weight: 400;
        font-family: sans-serif;
        padding: 20px 0 10px;
      }
  
      > a {
        margin: 30px auto 0;
      }
  
      > p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        font-family: sans-serif;
        color: #4a4a4a;
        margin: 10px 0;
      }
  
      > p a {
        color: #48bf91;
      }
  
      .already-div {
        margin-top: 40px;
        p {
          margin: 0;
          padding: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #4a4a4a;
          a {
            font-family: sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 14px;
            color: #48bf91;
          }
        }
      }
    }
  
    .form-floating > label {
      color: #4a4a4a;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
    }
    .inputfeildarea input,
    .form-feild input[type="text"],
    .form-feild input[type="password"],
    .otpinputfeild input,
    .form-feild input[type="email"],
    .form-feild input[type="number"] {
      width: 100%;
      border-bottom: 1px solid #ccc;
      border-radius: 0;
      color: #4a4a4a;
      font-size: 14px;
      font-weight: 400;
      margin-top: 10px;
      padding: 15px 15px 0;
      &:focus {
        border-bottom: 1px solid #1877f2;
      }
    }
  
    .inputfeildarea {
      position: relative;
    }
  
    .iconfeild {
      position: absolute;
      bottom: 13px;
      width: 30px;
      right: 12px;
      background: white;
      text-align: end;
    }
  
    .otpinputfeild input {
      border-bottom: 1px solid #ccc;
      width: 62px !important;
      margin: 0 20px;
    }
  
    .linkgreen {
      padding: 10px 0;
      font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 14px;
      color: #48bf91;
    }
    .forget-mg {
      margin: 20px 0 40px 0;
    }
  
    .f-18 {
      font-size: 18px !important;
      color: #4a4a4a !important;
    }
  
    .mg-tb-40 {
      margin: 40px 0;
    }
    .form-width {
      max-width: 430px;
      margin: 0 auto;
    }
    .google-btn {
      border: 2px solid #adb3bc;
      border-radius: 10px;
      color: #000;
      font-weight: 500;
      font-size: 16px;
      font-family: sans-serif;
      font-size: 18px;
      height: 55px;
      line-height: 50px;
      max-width: 430px;
    }
  
    .google-btn span.icongoogle {
      margin: 0px 10px 0 0;
      position: relative;
      top: -2px;
    }
  
    .facebook-btn {
      border: 2px solid #1877f2;
      background: #1877f2;
      border-radius: 10px;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
      font-family: sans-serif;
      font-size: 18px;
      height: 55px;
      line-height: 50px;
      max-width: 430px;
      margin-bottom: 40px !important;
    }
  
    .facebook-btn span.icongoogle {
      margin: 0px 10px 0 0;
      position: relative;
      top: -2px;
    }
  
    a.linkgreen.active {
      color: #48bf91;
    }
  
    p.pad-lf50 {
      font-family: sans-serif;
      font-weight: 400;
      font-size: 18px;
      max-width: 359px;
      text-align: center !important;
      margin: auto;
      margin-bottom: 50px;
    }
  
    .optformarea form div {
      justify-content: center;
    }
    .findcontent {
      position: relative;
      padding: 30px 50px;
      text-align: left;
      font-size: 14px;
      color: #4a4a4a;
      font-weight: 300;
      max-width: 375px;
    }
  
    .findcontenticon {
      position: absolute;
      top: 30px;
      left: 9px;
      font-size: 18px;
      font-weight: 500;
      color: #4a4a4a;
    }
  }
  
  @media only screen and (max-width: 992px) {
    .authbodyarea {
      .authbbox {
        width: 100%;
      }
    }
    .mobile-d-none {
      display: none;
    }
  
    // .bgimage {
    //   background-image: url(../images/loginbannerside.png) no-repeat;
    //   width: 100%;
    //   background-size: cover;
    // }
  
    .otpinputfeild input {
      width: 3em !important;
    }
  }
  
  @media screen and (min-width: 576px) and (max-width: 768px) {
  }
  
  @media screen and (min-width: 0) and (max-width: 576px) {
    .authbodyarea {
      .findcontent {
        max-width: 100%;
        padding: 20px 30px 20px 50px;
      }
  
      .otpinputfeild input {
        width: 40px !important;
      }
    }
  
    .bg-img-auth {
      // background-image: url("../images/loginbannerside.png");
      // background-repeat: no-repeat;
      // background-size: cover;
      // background-position: center center;
      background-image: none !important;
    }
    .authbodyarea .f-18 {
      font-size: 15px !important;
    }
    .authbodyarea .linkgreen {
      font-size: 14px;
    }
    .authbodyarea  p.pad-lf50 {
      font-size: 15px;
      max-width: 359px;
      margin-bottom: 50px;
    }
    .authbodyarea .authbbox > p.para {
      font-size: 15px;
  }
  .authbodyarea .authbbox {
    padding: 30px 50px;
    width: 80%;
    margin: 0 auto;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  }
  



  .active-slide-css{
    background: white!important;
    color: blue!important;
    border: 3px solid blue;
  }


  .parent-container{
    margin-top: 100px;
  }


  .user-button {
    button#dropdown-basic-button {
      width: 30px;
      height: 30px;
      background: #fafafb;
      box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
      border-radius: 8px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:focus {
        border: none;
      }
    }
  
    .dropdown-toggle::after {
      display: none;
    }
  }



  #example::after {
    content: attr(data-text);
}