.bgPink {
    background-color: $pink;
}

.bg-PaleGrey {
    background-color: $paleGrey;
}

.bg-PaleBrown {
    background-color: $paleBrown;
}

.red-color {
    color: $red;
}