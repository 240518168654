// Dashboard Notification Popup Css Starts Here
.notification-modal {

  .modal-heading {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    padding: 10px !important;
  }
  .modal-content {
    border-radius: 15px;
    width: 532px !important;
    background-color: #ffffff;

  }
  .modal-dialog {
    margin-right: 30px;
    margin-top: 90px;
  }
  .modal-body {
    .header {
      h6 {
        font-weight: 500;
        span {
          font-size: 18px;
          font-weight: 500;
        }
      }
      .btns-time {
        cursor: pointer;
        border-radius: 50%;
        width: 25px;
        height: 25px;
      }
    }
    .notification-container {
      height: calc(100vh - 235px);
      overflow-y: scroll;
      &::-webkit-scrollbar {
        // width: 3px;
        display: none;
      }
    }
  }
}


.notif_para{
    /* Lorem ipsum dolor sit amet, consectetur adipiscing elit. */
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    width: 300px;
  }