.product-card {
    background: white;
    height: 520px;
    border-radius: 20px;
    margin-top: 20px;
    padding: 25px;
  
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
    .product-img {
      border-radius: 10px;
      max-width: 300px;
      margin: 0 auto;
      position: relative;
      img {
        border-radius: 20px;
        object-fit: cover;
      }
      .badge {
        opacity: 1;
        position: absolute;
        top: 20px;
        left: 0;
        color: black;
        width: 80px;
        height: 40px;
        background: rgba(252, 66, 66, 0.705);
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
  
        span {
          color: white;
          font-size: 14px;
          font-weight: 500;
        }
      }
  
      &:hover {
        .badge {
          opacity: 1;
          transition: 0.5s ease in;
        }
      }
    }
    .product-title {
      font-family: sans-serif;
      font-weight: 600;
      font-size: 22px;
      color: black;
      margin-top: 20px;
    }
    .product-price {
      color: rgb(41, 41, 41);
      font-size: 20px;
      font-family: sans-serif;
      font-weight: 600;
    }
    strike {
      color: red;
      font-size: 18px;
      font-family: sans-serif;
      font-weight: 500;
    }
    .product-btn {
      background: black !important;
      margin-top: 20px;
      border-radius: px;
      outline: none;
      box-shadow: none;
      border: none;
      height: 45px;
      font-family: sans-serif;
      margin-top: 15px;
      border: 1px solid black;
      color: white;
      font-weight: 500;
      path {
        fill: white;
        transition: 0.3s ease-in;
      }
  
      &:focus,
      &:hover {
        background: black !important;
        transition: 0.3s ease-in;
        color: white;
  
        path {
          fill: white;
          transition: 0.3s ease-in;
        }
      }
      span {
        padding-left: 5px;
        margin-top: 20px;
      }
    }
  }