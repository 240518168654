.toast-btn {
  background: #00bd70;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 240px;
  height: 60px;
  border: none;
  border-radius: 10px;
  margin-top: 50px;
}
