.privacy-section {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 24px;
    overflow: scroll;
    // height: 100%;
    height: 750px;
    &::-webkit-scrollbar {
     display: none;
     
    }
  h6 {
  font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    color: #000000;
    padding: 12px;
    border-bottom: 1px solid rgb(173,179,188, 0.5);
  }

  .privacy-content{
    padding: 30px 0 0 0;
    p{
      font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #4A4A4A;

    }
  }
}


@media screen and (min-width: 1700px) and (max-width: 1920px) {
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .main-privacy-section {
    .privacy-section .privacy-content p {
    font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #4a4a4a;
    }
    .settings .setting-tab .inputfield a {
      font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      padding-left: 25px;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .main-privacy-section {
    .privacy-section {
      background: #ffffff;
      border-radius: 20px;
      padding: 24px;
      height: 100%;
      margin-top: 20px;
    }
    .privacy-section .privacy-content p {
    font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #4a4a4a;
    }
    .settings .setting-tab .inputfield a {
      font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      padding-left: 25px;
    }
  }
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .main-privacy-section {
    .privacy-section {
      background: #ffffff;
      border-radius: 20px;
      padding: 24px;
      height: 100%;
      margin-top: 20px;
    }
    .privacy-section .privacy-content p {
    font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #4a4a4a;
    }
    .settings .setting-tab .inputfield a {
      font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      padding-left: 25px;
    }
  }
}

@media (min-width: 1199px) and (max-width: 1500px) {
  .main-privacy-section{
    .privacy-col {
      width: 45%;
    }
  
    .setting-col {
      width: 30% !important;
    }
  }

}
@media (min-width: 1025px) and (max-width: 1198px) {
  .main-privacy-section {
    .privacy-col {
      width: 40%;
    }

    .setting-col {
      width: 30% !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main-privacy-section {
    .privacy-col {
      width: 45%;
    }

    .setting-col {
      width: 44% !important;
    }
  }
}