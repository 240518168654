.icon-main-container {
  background: white;
  border: 1px solid #f5f1fe;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
  margin-top: 50px;
  cursor: pointer;

  .main-heading {
    /* ✏️ Title */
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #363636;
    margin-bottom: 30px;
  }

  .first-heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #363636;
    padding-left: 20px;
  }

  .second-heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #899ca8;
    padding-left: 20px;
  }

  .icon-container {
    border-radius: 26px;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.7s;

    // &:hover{
    //     transition: 0.7s;
    //     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    //     transform: translateY(-10%);
    // }
  }
  transition: 0.7s;


  &:hover{
            transition: 0.7s;
            box-shadow: rgb(38, 57, 77) 0px 20px 40px -20px;
                    transform: translateY(-5%);
  }
}
