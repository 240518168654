.dashboard-sidebar-css {
  height: 100vh;
  width: 100%;
  background: rgb(240, 240, 240);
  min-height: 784px;
  padding: 15px 0;
  width: 237px;

  .logo-container {
    padding-left: 30px;
    .cuetip-text {
      /* Cuetip */
      font-family: "Formular";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #000000;
    }
  }

  .icon-container {
    padding-top: 36px;
    ul {
      padding: 0;
      margin: 0;
      padding-right: 27px;
      a {
        li {
          list-style: none;
          font-family: "Formular";
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
          line-height: 18px;
          color: #5c5c5c;
          background: rgb(240, 240, 240);
          padding: 14px 0 14px 30px;
          width: 100%;
          transition: 0.1s ease-in;
          border-radius: 0 23px 23px 0;

          &:hover {
            background: #00bd70;
            border-radius: 0 23px 23px 0;
            color: white;
          }
        }

        &.active{
            li{
                background: #00bd70;
                border-radius: 0 23px 23px 0;
                color: white; 
            }
        }
      }
    }
  }
}
