// scss-docs-start theme-color-variables
$primary: #0d6efd !default;
$secondary: #6c757d !default;
$success: #198754 !default;
$info: #0dcaf0 !default;
$warning: #ffc107 !default;
$red: #dc3545 !default;
$dark: #212529 !default;
$white: #fff !default;
$black: #000 !default;

//Custom Colors
$pink: #eebec8;
$paleGrey: #d5dac8;
$paleBrown: #dbd0c6;
$paleWhite: #edf5f7;
$orange: #d16234;