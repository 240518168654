.popup-btn {
  background: white;
  border-radius: 10px;
  border: 1px solid #00bd70;
  height: 40px;
  color: #00bd70;
  /* Create Project */
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #00bd70;
  padding: 6px 27px;
  margin: 50px;
  &:hover,
  &:focus {
    background: #00bd70 !important;
    color: white;
    border: 1px solid #00bd70 !important;
    outline: none;
  }
}

.stepper-modal {
  .modal-dialog {
    min-width: 1047px;
  }
  .modal-content {
    height: 620px;
    margin: 0 auto;
    background: white;

    .modal-title {
      /* Create Project */
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      font-feature-settings: "tnum" on, "lnum" on;
      color: #00bd70;
    }
    .modal-desc {
      /* Create your project and add your cycles accordingly. */
      font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
    }
  }
  .modal-footer {
    justify-content: start;
  }
  .modal-header{
    padding: 30px;
  }
  .modal-body{
    padding: 30px;
  }
  .modal-footer{
    padding: 30px;
  }
}

.modal-bottom-border {
  border-bottom: 5px solid #00bd70;
  transition: 0.3s;
}

.green-btn {
  width: 200px;
  height: 44px;
  background: #00bd70;
  border-radius: 10px;
  &:hover,&:focus{
    background: #00bd70!important;
    color: white!important;
  }
}

.gray-btn {
  width: 200px;
  height: 44px;
  background: white;
  border-radius: 10px;
  /* Back */
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #5c5c5c;

  &:hover,&:focus{
    background: white!important;
    color: #5c5c5c!important;
    border: none;
    box-shadow: none;
  }
}



.signup-form {

    input[type="radio"] {
        /* styles for all radio buttons */
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      input[type="radio"]:checked {
        /* styles for the checked radio button */
        background-color: red!important;
      }
    
  
    .form-floating {
      label {
        font-family: "Ageo";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #707581;
        padding: 13px 16px;
        &:focus {
          position: absolute;
          top: -10px !important;
        }
      }
  
      textarea {
        width: 100%;
        height: 43px;
        border: 1px solid #ceced4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-family: "Ageo";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 20px;
        padding: 13px 16px !important;
        height: 100px;
        resize: none;
        &::-webkit-scrollbar {
          display: none;
        }
        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid #00BD70;
  
          label {
            color: blue !important;
          }
        }
  
        &:not(:placeholder-shown) ~ label {
          background: white;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }
  
        &:focus ~ label {
          background: white;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }
      }
  
      input {
        width: 100%;
        height: 43px;
        border: 1px solid #ceced4;
        border-radius: 8px;
        display: flex;
        align-items: center;
        font-family: "Ageo";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 35px;
        padding: 13px 16px !important;
        color: #00BD70;
  
        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid #00BD70;
  
          label {
            color: blue !important;
          }
        }
  
        &:not(:placeholder-shown) ~ label {
          background: white;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }
  
        &:focus ~ label {
          background: white;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 5px;
        }
      }


  

      select {
        width: 100%;
        height: 43px;
        border: 1px solid #ceced4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        font-family: "Ageo";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 30px;
        padding: 13px 16px !important;
        resize: none;
        &::-webkit-scrollbar {
          display: none;
        }
        &:focus {
          outline: none;
          box-shadow: none;
          border: 1px solid #00BD70;
  
          label {
            color: blue !important;
          }
        }
  
        &:not(:placeholder-shown) ~ label {
          background: white;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }
  
        &:focus ~ label {
          background: #f6f6fa;
          padding: 0;
          margin: 0;
          top: 0;
          opacity: 1;
          display: inline;
          width: auto;
          height: auto;
          left: 12px;
          padding: 0 8px;
        }
      }
    }
  }


  input[type="radio"] {
    /* styles for all radio buttons */
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  input[type="radio"]:checked {
    /* styles for the checked radio button */
    background-color: red!important;
  }
