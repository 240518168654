.product-card-two {
    cursor: pointer;
    margin-top: 30px;
    padding: 10px;
  
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  
      &:hover {
        .front {
          opacity: 0;
          transition: 0.4s ease-in;
        }
        .back {
          opacity: 1;
          transition: 0.4s ease-in;
          // transform: translateX(100px);
          top: 0;
          left: 0;
  
          .heart {
            position: absolute;
            top: 10px;
            right: 10px;
            transition: 0.3s ease-in;
            &:hover {
              background: rgb(197, 197, 197);
              path {
                fill: white;
              }
            }
          }
          .cart {
            position: absolute;
            top: 60px;
            right: 10px;
            transition: 0.3s ease-in;
            &:hover {
              background: rgb(197, 197, 197);
              path {
                fill: white;
              }
            }
          }
          .chat {
            position: absolute;
            top: 110px;
            right: 10px;
            transition: 0.3s ease-in;
            &:hover {
              background: rgb(197, 197, 197);
              path {
                fill: white;
              }
            }
          }
        }
      }
    }
  
    .img-wrapper {
      position: relative;
      overflow: hidden;
      height: 100%;
      height: 279px;
      .front {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
      }
      .back {
        position: absolute;
        left: 0;
        opacity: 0;
  
        .heart {
          width: 45px;
          height: 45px;
          background: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 20px;
          right: 20px;
          cursor: pointer;
        }
  
        .cart {
          width: 45px;
          height: 45px;
          background: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 20px;
          right: 20px;
          cursor: pointer;
        }
  
        .chat {
          width: 45px;
          height: 45px;
          background: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 20px;
          right: 20px;
          cursor: pointer;
        }
      }
    }
  
    .product-desc {
      font-family: sans-serif;
      font-weight: 500;
      font-size: 22px;
      color: rgb(39, 39, 39);
    }
    .sub-desc {
      font-size: 14px;
      font-family: sans-serif;
      font-weight: 400;
      color: rgb(172, 172, 172);
    }
  }