.search-container {
  width: 35px;
  height: 35px;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.search-container-expanded {
    width: 105px;
    height: 35px;
    border: 1px solid #cfcfcf;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
  }
  

  .expanded-search{
    .input-group{
        width: 264px;
        height: 34px;
    }
    input.form-control{
        height: 32px;
    }
    span#basic-addon2{
        height: 32px;
        cursor: pointer;
        &:hover{
            path{
                color: blue;
            }
        }
    }
  }