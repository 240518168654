// typewritter-banner-starts

.typewriter {
    .container {
      max-width: 1440px;
    }
  }
  .typewrite-banner {
    // background: white;
    height: 600px;
    background-image: url("../../assets/images/typewritter-banner/top-banner-21.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    background-position: right center;
  
    .printing-made-easier {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      border-radius: 3px;
      color: #11b196;
      background-color: rgba(17, 177, 150, 0.1);
      display: inline-block;
      padding: 6px 13px;
      position: relative;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-top: 100px;
      margin-bottom: 20px;
    }
  
    .description {
      font-size: 24px;
      color: #7e7e7e;
      font-weight: 400;
      line-height: 1.6;
      margin-top: 20px;
      margin-bottom: 30px;
    }
    .banner-heading {
      color: black;
      font-family: sans-serif;
      font-size: 54px;
      font-weight: 700;
    }
  
    .Typewriter {
      color: black;
      font-family: sans-serif;
      font-size: 54px;
      font-weight: 700;
    }
  
    .red-btn {
      background-color: #dd1d26;
      color: white;
      font-family: sans-serif;
      font-weight: 600;
      border: none;
      box-shadow: none;
      outline: none;
      font-size: 16px;
      width: 120px;
      height: 50px;
      &:focus,
      &:hover {
        background: #94141a;
      }
    }
  
    .watch-now {
      background: white;
      color: black;
      font-family: sans-serif;
      font-weight: 600;
      border: none;
      box-shadow: none;
      outline: none;
      font-size: 16px;
      width: 200px;
      height: 50px;
      &:focus {
        background: white !important;
        color: black !important;
      }
    }
  }
  // type-writer-banner-ends