
.counter-card{
    background: linear-gradient(#2A2A72 , #009FFD) ;
    height: 300px;
    border-radius: 30px;
    padding: 20px;
    animation: myAnim 1s ease 0s 1 normal forwards;

  
    .counter-icon{
      text-align: center;
      font-size: 44px;
      color: white;
    }
    .counter-count{
      color: white;
      font-size: 30px;
      font-weight: 800;
      text-align: center;
      margin-top: 20px;
      
    }
    .counter-heading{
      font-size: 34px;
      font-weight: 800;
      color: white;
      text-align: center;
      font-family: sans-serif;
    }
  }


  @keyframes myAnim {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }