.navigation-tabs {
  min-height: 130px;
  border-radius: 10px;
  background: linear-gradient(#2A2A72 , #009FFD) ;
  transition: background 0.5s ease;
  margin-bottom: 20px;
  padding: 30px;
  &:hover{
    cursor: pointer;
    transition: 0.6s ease-in;
    background: linear-gradient(#56c1ff ,#2A2A72 ) ;
  }

  .main-heading{
    color: white;
    font-size: 22px;
    font-weight: 800;
  }
  .desc{
    color: white;
    font-size: 14px;
    
  }
}

.mt_50{
    padding-top: 100px;
    background: black;
    height: 100%;
}

a{
    text-decoration: none!important;
}

@media (max-width:1600px){
    .navigation-tabs{
        min-height: 150px;
    }
}