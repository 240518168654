.editprofile {
    // margin-bottom: 150px;
    background-color: #F5F5F5;
  }
  .edit-profile-form {
    background: #ffffff;
    border-radius: 25px;
    padding: 22px 24px 22px 24px;
    height: 100%;
  
    h6 {
      font-family: sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      margin-bottom: 0;
      padding-top: 10px;
    }
    .border-bottomm {
      border-bottom: 1px solid rgb(173, 179, 188, 0.5);
      margin: 15px 0;
    }
  
    .profile-form {
      margin-top: 30px;
      .img-div {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin: 0 auto;
        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          text-align: center;
          display: flex;
          cursor: pointer;
        }
        &::after {
          content: url("./images/camera.svg");
  
          position: absolute;
          width: 25px;
          height: 25px;
          background: #0076be;
          border-radius: 50%;
          border: 1px solid white;
          top: 77%;
          right: 10%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .file-upload {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
  
      .input-fields {
        .form-floating {
          margin-top: 15px;
        }
        input {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          border-bottom: 1px solid rgb(173, 179, 188, 0.5);
          border-radius: 0;
          &:focus {
            border-bottom: 1px solid #0076be;
          }
        }
        textarea {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          border-bottom: 1px solid rgb(173, 179, 188, 0.5);
          border-radius: 0;
          margin-top: 10px;
          height: 72px;
          resize: none;
          padding: 0 12px;
  
          &:focus {
            border-bottom: 1px solid #0076be;
          }
        }
        label {
          font-family: sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;
        }
        .form-floating>.form-control:not(:placeholder-shown)~label{
          opacity: 1;
        }
        .form-floating>.form-control:focus~label{
          opacity: 1;
        }
        .bio-label {
          font-family: sans-serif;
          padding-left: 12px;
          padding-top: 26px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;
        }
      }
      button {
        font-family: sans-serif;
        background: #0076be;
        border-radius: 10px;
        padding: 15px 0;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
        transition: 0.2s ease-in;
        margin-top: 50px;
        &:hover {
          background: white;
          color: #0076be;
        }
      }
    }
  }
  