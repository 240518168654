.new-sidebar{
  
.sidebar-new {
  background: darkslategrey;
  padding: 20px;
  color: white;
  transition: 0.4s ease-in;
  height: 100vh;
  position: relative;
}

.sidebar-open{
  width: 250px;

}

.sidebar-closed {
  width: 70px!important;

}

ul {
  list-style-type: none;
  padding: 0;
}

.sidebar__listItem {
  margin: 30px 10px;
  display: flex;
  height: 20px;
  align-items: center;
}

.sidebar__listItem span {
  white-space: nowrap;
}

.sidebar__icon {
  margin: 0 15px 0 0;
}

/* FADE ANIMATIONS */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.navigation-btn{
  position: absolute;
  top: 10px;
  right: -15px;
  background: white;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid darkslategrey;
  cursor: pointer;
  transition: 0.4s ease-in;

  path{
    fill: darkslategrey;
  }
}

.active-navigation{
  transform: rotate(180deg);
  transition: 0.4s ease-in;

}

}