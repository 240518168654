.demo-banners {
    background: black;
    height: 100vh;
    padding: 30px 0;
  
    .demo-heading {
      color: white;
      font-weight: 600;
      font-size: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .counter {
      color: rgb(22, 150, 255);
    }
  
    .demo-description {
      margin-top: 20px;
      color: #90a4ae;
      font-size: 19px;
      line-height: 1.7em;
      text-align: center;
    }
  
    .post-thumbnail {
      box-shadow: 0 0 1px 1px rgb(255 255 255 / 12%) inset;
      margin-top: 30px;
      position: relative;
  
  
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: 0.3s ease;
  
      }
  
      .icons{
        display: none;
      }
      &:hover {
        .overlay {
          background-color: rgba(0, 0, 0, 0.5);
          cursor: pointer;
          transition: 0.3s ease;
        }
        .icons{
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          fill: white;
          transition: 0.3s ease;
          cursor: pointer;
        }
      }
    }
  }