.hover-product-section {
    padding: 80px 0;
}

.hover-product-list {
    position: relative;

    .list-title {
        text-align: center;
        text-transform: uppercase;
        margin: 0 0 20px;
    }

    .side {
        display: none;
        flex: 1 1 33%;

        img {
            position: absolute;
            top: 0;
            max-width: 33%;
            opacity: 0;
            visibility: hidden;
            transition: .3s;
            width: auto;
            height: auto;
        }
    }

    .left-side {
        img {
            left: 0;
        }
    }

    .right-side {
        img {
            right: 0;
        }
    }

    .item-title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 5px;
        flex: 1 0 100%;

        p {
            margin: 0;
        }

        .title {
            padding-left: 15px;
            padding-right: 15px;

            a {
                transition: .3s;
                color: #000;
                text-decoration: none;

                &:hover {
                    color: #656565;
                }
            }
        }

        .side-title {
            transition: .3s;

            span {
                line-height: 1;
                border-radius: 20px;
                padding-top: 4px;
                padding-left: 5px;
                padding-right: 5px;
                border: 1px solid transparent;
                transition: .3s;
                min-height: 41px;
                min-width: 41px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #656565;
                font-weight: 500;
                text-decoration: none;
                font-size: 14px;

                &:hover {
                    border: 1px solid #dadada;
                    color: #000;
                }
            }

            a {
                line-height: 1;
                border-radius: 20px;
                padding-top: 4px;
                padding-left: 5px;
                padding-right: 5px;
                border: 1px solid transparent;
                transition: .3s;
                min-height: 41px;
                min-width: 41px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #656565;
                font-weight: 500;
                text-decoration: none;
                font-size: 14px;

                &:hover {
                    border: 1px solid #dadada;
                    color: #000;
                }
            }
        }
    }
}

.hover-product-list__item {
    display: flex;
    align-items: center;
}

.hover-product-list__item {
    &.active {
        .item-title {
            .side-title {
                opacity: 1;
            }
        }

        .side {
            img {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.hover-product-list__bottom-link {
    margin-top: 30px;
    text-align: center;
}

@media (min-width:992px) {
    .hover-product-list {
        .side {
            display: flex;
        }

        .item-title {
            flex: 1 0 33.3%;

            .title {
                font-size: 52px;
            }

            .side-title {
                opacity: 0;
            }
        }
    }
}

@media (min-width:576px) {
    .hover-product-list {
        .item-title {
            padding: 15px 5px;

            .title {
                font-size: 40px;
            }

            .side-title {
                span {
                    font-size: 16px;
                }

                a {
                    font-size: 16px;
                }
            }
        }
    }
}