.search-section-css{
    $color_1: #55595c;
$background-color_1: #fff;

.search-sec {
	padding: 2rem;
}
.search-slt {
	display: block;
	width: 100%;
	font-size: 0.875rem;
	line-height: 1.5;
	color: $color_1;
	background-color: $background-color_1;
	background-image: none;
	border: 1px solid #ccc;
	height: calc(3rem + 2px) !important;
}
.wrn-btn {
	width: 100%;
	font-size: 16px;
	font-weight: 400;
	text-transform: capitalize;
	height: calc(3rem + 2px) !important;
}
.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: -1;
}
.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
    display: none;
}
@media (min-width: 992px) {
	.search-sec {
		position: relative;
        top: -114px;
		background: rgba(26, 70, 104, 0.51);
	}
}
@media (max-width: 992px) {
	.search-sec {
		background: #1A4668;
	}
}
}