.testimonial-section-css{
    .main-testimonial {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.54), rgba(49, 49, 49, 0.69)),
          url("../../img/testimonial/background-img-one.jpg");
      
        background-position: center center;
        height: 490px;
        .main-heading-div {
          height: 50%;
        }
        h2 {
          text-align: center;
          color: white;
          font-size: 40px;
          padding-top: 30px;
      
        }
        .desc {
          font-size: 16px;
          color: white;
          width: 50%;
          margin: 0 auto;
        }
        .quote {
          text-align: center;
          font-size: 50;
          font-size: 60px;
          color: white;
          margin-bottom: 20px;
        }
        .main-review-slider {
          height: 100%;
      
          .test-detail {
            .client-detail {
              display: flex;
              margin: 0 auto;
              text-align: center;
              justify-content: center;
              align-items: center;
              margin-top: 20px;
      
              .avatar {
                width: 60px;
                height: 60px;
                border-radius: 50%;
      
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 50%;
                }
              }
              .content {
                margin-left: 15px;
      
                h6 {
                  font-size: 14px;
                  color: white;
                  margin-bottom: 0;
                  text-align: left;
      
                }
                p {
                  font-size: 14px;
                  color: white;
                  margin-bottom: 0;
      
                }
              }
            }
          }
        }
      }
      .swiper {
        width: 100%;
        height: 100%;
        // overflow: visible; 
      }
      
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: transparent;
      
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }
      
      .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal,
      .swiper-pagination-custom,
      .swiper-pagination-fraction {
        bottom: 140px;
        left: 0;
        width: 100%;
      }
      span.swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: white;
      }
      span.swiper-pagination-bullet {
        border: 2px solid white;
      }
      
      .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal,
      .swiper-pagination-custom,
      .swiper-pagination-fraction {
        // bottom: 26px;
        left: 0;
        width: 100%;
        top: 211px;
      
      }
      
      @media screen and (min-width: 1700px) {
        .main-testimonial {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.54), rgba(49, 49, 49, 0.69)),
            url("../../img/testimonial/background-img-one.jpg");
        
          background-position: center center;
          height: 490px;
          .main-heading-div {
            height: 50%;
          }
          h2 {
            text-align: center;
            color: white;
            font-size: 40px;
          }
          .desc {
            font-size: 16px;
            color: white;
            width: 50%;
            margin: 0 auto;
          }
          .quote {
            text-align: center;
            font-size: 50;
            font-size: 60px;
            color: white;
            margin-bottom: 20px;
          }
          .main-review-slider {
            height: 100%;
        
            .test-detail {
              .client-detail {
                display: flex;
                margin: 0 auto;
                text-align: center;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
        
                .avatar {
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
        
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                  }
                }
                .content {
                  margin-left: 15px;
        
                  h6 {
                    font-size: 14px;
                    color: white;
                    margin-bottom: 0;
                    text-align: left;
        
                  }
                  p {
                    font-size: 14px;
                    color: white;
                    margin-bottom: 0;
        
                  }
                }
              }
            }
          }
        }
      }
      
      @media screen and (min-width: 992px) and (max-width: 1400px) {
        .main-testimonial {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.54), rgba(49, 49, 49, 0.69)),
            url("../../img/testimonial/background-img-one.jpg");
        
          background-position: center center;
          height: 490px;
          .main-heading-div {
            height: 50%;
          }
          h2 {
            text-align: center;
            color: white;
            font-size: 40px;
          }
          .desc {
            font-size: 16px;
            color: white;
            width: 50%;
            margin: 0 auto;
          }
          .quote {
            text-align: center;
            font-size: 50;
            font-size: 60px;
            color: white;
            margin-bottom: 20px;
          }
          .main-review-slider {
            height: 100%;
        
            .test-detail {
              .client-detail {
                display: flex;
                margin: 0 auto;
                text-align: center;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
        
                .avatar {
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
        
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                  }
                }
                .content {
                  margin-left: 15px;
        
                  h6 {
                    font-size: 14px;
                    color: white;
                    margin-bottom: 0;
                    text-align: left;
        
                  }
                  p {
                    font-size: 14px;
                    color: white;
                    margin-bottom: 0;
        
                  }
                }
              }
            }
          }
        }
      }
      
      @media screen and (min-width: 768px) and (max-width: 992px) {
        .main-testimonial {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.54), rgba(49, 49, 49, 0.69)),
            url("../../img/testimonial/background-img-one.jpg");
        
          background-position: center center;
          height: 490px;
          .main-heading-div {
            height: 50%;
          }
          h2 {
            text-align: center;
            color: white;
            font-size: 40px;
          }
          .desc {
            font-size: 16px;
            color: white;
            width: 50%;
            margin: 0 auto;
          }
          .quote {
            text-align: center;
            font-size: 50;
            font-size: 60px;
            color: white;
            margin-bottom: 20px;
          }
          .main-review-slider {
            height: 100%;
        
            .test-detail {
              .client-detail {
                display: flex;
                margin: 0 auto;
                text-align: center;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
        
                .avatar {
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
        
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                  }
                }
                .content {
                  margin-left: 15px;
        
                  h6 {
                    font-size: 14px;
                    color: white;
                    margin-bottom: 0;
                    text-align: left;
        
                  }
                  p {
                    font-size: 14px;
                    color: white;
                    margin-bottom: 0;
        
                  }
                }
              }
            }
          }
        }
      }
      
      @media screen and (min-width: 576px) and (max-width: 768px) {
        .main-testimonial {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.54), rgba(49, 49, 49, 0.69)),
            url("../../img/testimonial/background-img-one.jpg");
        
          background-position: center center;
          height: 490px;
          .main-heading-div {
            height: 50%;
          }
          h2 {
            text-align: center;
            color: white;
            font-size: 40px;
          }
          .desc {
            font-size: 16px;
            color: white;
            width: 80%;
            margin: 0 auto;
          }
          .quote {
            text-align: center;
            font-size: 50;
            font-size: 60px;
            color: white;
            margin-bottom: 20px;
          }
          .main-review-slider {
            height: 100%;
        
            .test-detail {
              .client-detail {
                display: flex;
                margin: 0 auto;
                text-align: center;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
        
                .avatar {
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
        
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                  }
                }
                .content {
                  margin-left: 15px;
        
                  h6 {
                    font-size: 14px;
                    color: white;
                    margin-bottom: 0;
                    text-align: left;
        
                  }
                  p {
                    font-size: 14px;
                    color: white;
                    margin-bottom: 0;
        
                  }
                }
              }
            }
          }
        }
      }
      
      @media screen and (min-width: 0) and (max-width: 576px) {
        .main-testimonial {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.54), rgba(49, 49, 49, 0.69)),
            url("../../img/testimonial/background-img-one.jpg");
        
          background-position: center center;
          height: 490px;
          .main-heading-div {
            height: 50%;
          }
          h2 {
            text-align: center;
            color: white;
            font-size: 27px;
          }
          .desc {
            font-size: 12px;
            color: white;
            width: 100%;
            margin: 0 auto;
          }
          .quote {
            text-align: center;
            font-size: 50;
            font-size: 40px;
            color: white;
            margin-bottom: 20px;
          }
          .main-review-slider {
            height: 100%;
        
            .test-detail {
              .client-detail {
                display: flex;
                margin: 0 auto;
                text-align: center;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
        
                .avatar {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
        
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                  }
                }
                .content {
                  margin-left: 15px;
        
                  h6 {
                    font-size: 12px;
                    color: white;
                    margin-bottom: 0;
                    text-align: left;
        
                  }
                  p {
                    font-size: 12px;
                    color: white;
                    margin-bottom: 0;
        
                  }
                }
              }
            }
          }
        }
      }
       
      
      
      
}